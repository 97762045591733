import React, { useState, useEffect, useCallback } from 'react';
import { Form as RFFForm, Field } from 'react-final-form';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import FieldBSRenderText from '../../Common/FieldBSRenderText';

import styles from './index.module.css';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';
import { useAppSelector } from '../../../Reducers/Store';
import { displayDateOnly, parseDatesForServer } from '../../../Utils';
import { toast } from 'react-toastify';
import {
  composeValidators,
  centsValidation,
  requiredField,
} from '../../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../../Utils/InputFormatters';
import FieldBSRenderMoney from '../../Common/FieldBSRenderMoney';
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect';
import FieldBSRenderDate from '../../Common/FieldBSRenderDate';
import ICDDiagnosisCodesTable from './ICDDiagnosisCodesTable';
import {
  ClaimCmsinfoIcdcode,
  ICDStatusEnum,
} from '../../../ApiTypes/ClaimCmsinfoIcdcode';
import { ClaimCmsinfo } from '../../../ApiTypes/ClaimCmsinfo';
import { FaPlusCircle } from 'react-icons/fa';
import AddEditICDDiagnosisCodeModal from './AddEditICDDiagnosisCodeModal';
import ClaimCmsinfoApi from '../../../Api/ClaimCmsinfoApi';
import { Icdcode } from '../../../ApiTypes/Icdcode';
import { InsuranceType } from '../../../ApiTypes/InsuranceType';
import { PlanInsuranceType } from '../../../ApiTypes/PlanInsuranceType';
import { MedicareItem } from '../../../ApiTypes/MedicareItem';
import Loading from '../../Common/Loading';
import { boolean } from 'yargs';
import FieldBSRenderSelectHorizontalSearchable from '../../Common/FieldBSRenderSelectHorizontalSearchable';
import FieldBSRenderSelectSearchable from '../../Common/FieldBSRenderSelectSearchable';

export default function CMSInfoTab() {
  let { claimNumber } = useParams();

  const { claim } = useAppSelector((state) => state.currentClaimReducer);
  const { icdCodes, planInsuranceType, insuranceType, medicares } =
    useAppSelector((state) => state.reference);
  const [claimCmsinfo, setClaimCmsinfo] = useState<ClaimCmsinfo | null>(null);

  const [icdUnknownCodes, seticdUnknownCodes] = useState<ClaimCmsinfoIcdcode[]>(
    []
  );
  const [icdAcceptedCodes, seticdAcceptedCodes] = useState<
    ClaimCmsinfoIcdcode[]
  >([]);
  const [icdUnrelatedCodes, seticdUnrelatedCodes] = useState<
    ClaimCmsinfoIcdcode[]
  >([]);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [selectedicdCode, setSelectedicdCode] =
    useState<ClaimCmsinfoIcdcode | null>(null);
  const [diagnosisType, setDiagnosisType] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { userModel } = useAppSelector((state) => state.user);

  const getClaimCmsinfoIcdcodes = useCallback(() => {
    if (claimNumber) {
      ClaimCmsinfoApi.GetClaimCmsinfoForClaim(claimNumber)
        .then((res) => {
          if (res.data) {
            setClaimCmsinfo(res.data);
            ClaimCmsinfoApi.getClaimCmsinfoIcdCodes(claimNumber!, res.data.id)
              .then((res) => {
                if (res.data) {
                  seticdUnknownCodes(
                    res.data.filter(
                      (c) =>
                        c.statusId === ICDStatusEnum.Unknown ||
                        c.statusId === null
                    )
                  );
                  seticdAcceptedCodes(
                    res.data.filter(
                      (c) => c.statusId === ICDStatusEnum.Accepted
                    )
                  );
                  seticdUnrelatedCodes(
                    res.data.filter(
                      (c) => c.statusId === ICDStatusEnum.Unrelated
                    )
                  );
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setClaimCmsinfo(null);
      seticdAcceptedCodes([]);
      seticdUnrelatedCodes([]);
    }
  }, [claimNumber]);

  useEffect(() => {
    getClaimCmsinfoIcdcodes();
  }, [getClaimCmsinfoIcdcodes]);

  const handleNew = (t: number) => {
    setDiagnosisType(t);
    setShowEdit(true);
  };

  const handleDelete = (c: ClaimCmsinfoIcdcode) => {
    if (claimNumber) {
      setLoading(true);
      ClaimCmsinfoApi.deleteClaimCmsinfoIcdCode(claimNumber, c.id)
        .then((res) => {
          if (res.data.success) {
            getClaimCmsinfoIcdcodes();
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast.error('Failed to delete Diagnosis Code');
        });
    }
  };

  const addUpdate = (c: ClaimCmsinfoIcdcode, status: number | null) => {
    if (claimCmsinfo && c && claimNumber) {
      setLoading(true);
      c.claimCmsinfoId = claimCmsinfo.id;
      if (status) {
        c.statusId = status;
      } else {
        c.statusId = diagnosisType;
      }
      return ClaimCmsinfoApi.updateClaimCmsinfoIcdCode(claimNumber, c)
        .then((res) => {
          if (res.data.success) {
            getClaimCmsinfoIcdcodes();
            setShowEdit(false);
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast.error('Failed to update Diagnosis Code');
        });
    }
  };

  const handleEdit = (c: ClaimCmsinfoIcdcode) => {
    setDiagnosisType(c.statusId);
    setSelectedicdCode(c);
    setShowEdit(true);
  };

  const onSubmit = (values: ClaimCmsinfo) => {
    if (claimNumber && values) {
      if (values.mrreportable) {
        values.mrreportable =
          values.mrreportable.toString() === 'true' ? true : false;
      }
      if (!values.tpocamount) {
        values.tpocamount = 0;
      }
      return ClaimCmsinfoApi.UpdateClaimCmsinfo(claimNumber, values)
        .then((res) => {
          if (res.data.success) {
            toast.success('CMS Info Saved!', { autoClose: 1000 });
            getClaimCmsinfoIcdcodes();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className='w-100 h-100'>
      <RFFForm
        onSubmit={onSubmit}
        initialValues={
          claimCmsinfo
            ? {
                ...claimCmsinfo,
                logChanges: true,
              }
            : {
                claimNo: claimNumber,
                logChanges: true,
              }
        }
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit} className={`${styles.formWidth}`}>
              <div className={`${styles.form}`}>
                <div className={`${styles.column}`}>
                  <div className='w-100 d-flex'>
                    <div className='w-50 ps-2'>
                      <fieldset className='mb-3'>
                        <legend className=''>Medicare</legend>
                        <Field
                          name='medicareEligibilityId'
                          type='text'
                          label={
                            claim?.medicareQueryDate
                              ? `Medicare File sent on ${displayDateOnly(
                                  claim?.medicareQueryDate
                                )}`
                              : 'Claim has not been sent to Medicare'
                          }
                          options={medicares}
                          optionMethod={(options: MedicareItem[]) =>
                            options.map((o) => (
                              <option key={o.id} value={o.id}>
                                {o.status}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelect}
                        />
                      </fieldset>
                    </div>
                    <div className='w-50 pe-2'>
                      <Field
                        name='internalControlNumber'
                        label='ICN (Internal Control #)'
                        type='text'
                        component={FieldBSRenderText}
                      />
                    </div>
                  </div>
                  <div className='w-100 d-flex'>
                    <div className='w-50 pe-2'>
                      <Field
                        name='injuredPartyHicn'
                        label='Injured Party HICN'
                        type='text'
                        component={FieldBSRenderText}
                      />
                    </div>
                    <div className='w-50 pe-2'>
                      <Field
                        name='tin'
                        label='TIN'
                        type='text'
                        component={FieldBSRenderText}
                      />
                    </div>
                  </div>
                  <div className='w-50 pe-2'>
                    <Field
                      name='ormindicator'
                      type='checkbox'
                      label='ORM Indicator'
                      checked={!!values?.ormindicator}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  <div className='w-50 pe-2'>
                    <Field
                      name='ormterminationDate'
                      type='text'
                      label='ORM Termination Date'
                      // validate={requiredField}
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  </div>
                  <div className='w-50 pe-2'>
                    <Field
                      name='selfInsuranceIndicator'
                      type='checkbox'
                      label='Self Insurance Indicator'
                      checked={!!values?.selfInsuranceIndicator}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  <div className='w-50 pe-2'>
                    <Field
                      name='selfInsuranceTypeId'
                      label='Self Insurance Type'
                      options={insuranceType}
                      optionMethod={(options: InsuranceType[]) =>
                        options.map((o) => (
                          <option key={o.id} value={o.id}>
                            {o.name}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                  </div>
                  <div className='w-100 d-flex'>
                    <div className='w-50 pe-2'>
                      <Field
                        name='policyHolderFirstName'
                        label='Policyholder First Name'
                        type='text'
                        component={FieldBSRenderText}
                      />
                    </div>
                    <div className='w-50 pe-2'>
                      <Field
                        name='policyHolderLastName'
                        label='Policyholder Last Name'
                        type='text'
                        component={FieldBSRenderText}
                      />
                    </div>
                  </div>
                  <div className='w-50 pe-2'>
                    <Field
                      name='dbaname'
                      label='DBA Name'
                      type='text'
                      component={FieldBSRenderText}
                    />
                  </div>
                  <div className='w-50 pe-2'>
                    <Field
                      name='planInsuranceTypeId'
                      label='Plan Insurance Type'
                      options={planInsuranceType}
                      optionMethod={(options: PlanInsuranceType[]) =>
                        options.map((o) => (
                          <option key={o.id} value={o.id}>
                            {o.name}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                  </div>
                  <div className='w-100 d-flex'>
                    <div className='w-50 pe-2'>
                      <Field
                        name='tpocdate'
                        type='text'
                        label='TPOC Date'
                        // validate={requiredField}
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                    </div>
                    <div className='w-50 pe-2'>
                      <Field
                        name='tpocamount'
                        label='TPOC Amount'
                        type='text'
                        format={formatNumbers}
                        parse={cleanMoney}
                        validate={composeValidators(centsValidation)}
                        component={FieldBSRenderMoney}
                      />
                    </div>
                  </div>
                  <div className='w-100 d-flex'>
                    <div className='w-50 pe-2'>
                      <div className='w-100 d-flex'>
                        <div className='pe-2'>
                          <p>MRR Reportable:</p>
                        </div>
                        <div className='pe-2'>
                          <Field
                            name='mrreportable'
                            type='radio'
                            label='Yes'
                            value={true}
                            checked={
                              values?.mrreportable?.toString() === 'true'
                            }
                            component={FieldBSRenderCheckbox}
                          />
                        </div>
                        <Field
                          name='mrreportable'
                          type='radio'
                          label='No'
                          value={false}
                          checked={values?.mrreportable?.toString() === 'false'}
                          component={FieldBSRenderCheckbox}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${styles.column}`}>
                  <div className='w-50 pe-2'>
                    <Field
                      name='icdcauseOfInjury'
                      label='ICD-10 Alleged Cause of Injury'
                      options={icdCodes}
                      optionMethod={(options: Icdcode[]) =>
                        options.map((o) => ({
                          value: o.id,
                          label: o.name + ' : ' + o.description,
                        }))
                      }
                      component={FieldBSRenderSelectSearchable}
                    />
                  </div>
                  {claimCmsinfo && (
                    <div>
                      <>{loading && <Loading />}</>
                      <div className='w-100 pe-2'>
                        <div className='w-100 d-flex'>
                          <div className='pe-2'>
                            <p>Imported Diagnosis Code(s)</p>
                          </div>
                        </div>
                        <ICDDiagnosisCodesTable
                          data={icdUnknownCodes}
                          handleDelete={handleDelete}
                          handleEdit={handleEdit}
                          handleStatusChange={addUpdate}
                          icdCodeStatus={ICDStatusEnum.Unknown}
                        />
                      </div>
                      <div className='w-100 d-flex'>
                        <div className='w-50 pe-2'>
                          <div className='w-100 d-flex'>
                            <div className='w-50 pe-2'>
                              <p>Accepted Diagnosis Code(s)</p>
                            </div>
                            <div className='w-50 pe-2'>
                              <Button
                                type='button'
                                variant='primary'
                                size='sm'
                                className='button-icon-text'
                                onClick={() => {
                                  handleNew(ICDStatusEnum.Accepted);
                                }}
                              >
                                <FaPlusCircle /> New
                              </Button>
                            </div>
                          </div>
                          <ICDDiagnosisCodesTable
                            data={icdAcceptedCodes}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            handleStatusChange={addUpdate}
                            icdCodeStatus={ICDStatusEnum.Accepted}
                          />
                        </div>
                        <div className='w-50 pe-2'>
                          <div className='w-100 d-flex'>
                            <div className='w-50 pe-2'>
                              <p>Unrelated Diagnosis Code(s)</p>
                            </div>
                            <div className='w-50 pe-2'>
                              <Button
                                type='button'
                                variant='primary'
                                size='sm'
                                className='button-icon-text'
                                onClick={() => {
                                  handleNew(ICDStatusEnum.Unrelated);
                                }}
                              >
                                <FaPlusCircle /> New
                              </Button>
                            </div>
                          </div>
                          <ICDDiagnosisCodesTable
                            data={icdUnrelatedCodes}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            handleStatusChange={addUpdate}
                            icdCodeStatus={ICDStatusEnum.Unrelated}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex justify-content-center mt-3'>
                <Field
                  name='logChanges'
                  type='checkbox'
                  label='Add Change Log Note on Save'
                  checked={values.logChanges === true}
                  component={FieldBSRenderCheckbox}
                />
                <Button type='submit' variant='primary'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <AddEditICDDiagnosisCodeModal
        show={showEdit}
        setShow={setShowEdit}
        data={selectedicdCode}
        addUpdate={addUpdate}
      />
    </div>
  );
}
