import React, { useState, useMemo, useEffect } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  OnChangeFn,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { displayDateAndTimeLocal, displayDateOnly } from '../../Utils';
import { toast } from 'react-toastify';
import styles from './index.module.css';
import { TextGridObj } from '../../ApiTypes/TextGridObj';
import { Field, Form as RFFForm } from 'react-final-form';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Claimant } from '../../ApiTypes/Claimant';
import { TextLog } from '../../ApiTypes/TextLog';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import { FormApi } from 'final-form';
import TextLogApi from '../../Api/TextLogApi';
import { API_URL } from '../../Api';
import * as signalR from '@microsoft/signalr';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';

const connection = new signalR.HubConnectionBuilder()
  .withUrl('https://gasclaimsapi.georgia-admin.com/chatHub', {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
  })
  .build();

connection.onclose(function () {
  console.log('connecition closed');
});

export default function TextMessagesTable({
  data,
  getTextLogs,
}: {
  data: TextGridObj[];
  getTextLogs: () => void;
}) {
  const [claimant, setclaimant] = useState<Claimant | null>();
  const [conversationData, setconversationData] = useState<TextLog[]>([]);
  let formInstance: FormApi<TextLog>;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [isConnected, setisConnected] = useState<boolean>(true);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    let row = table.getSelectedRowModel().flatRows.map((r) => r.original)[0];
    if (row) {
      row.status = 'Read';
      const values = {
        firstName: row.firstName,
        lastName: row.lastName,
        cellPhone: row.claimantNumber,
      } as Claimant;
      setconversationData([]);
      setclaimant(values);
    }
  };

  const getConversationTextList = () => {
    if (claimant && claimant.cellPhone) {
      TextLogApi.getConversationTextList(claimant.cellPhone)
        .then((res) => {
          setconversationData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setconversationData([]);
    }
  };

  useEffect(() => {
    if (claimant?.cellPhone) {
      connection.off('Claimant_' + claimant?.cellPhone.replace('-', ''));
      disconnectConversation();
      getConversationTextList();
    }
  }, [claimant]);

  useEffect(() => {
    if (claimant && claimant?.cellPhone && !isConnected) {
      connectConversation();
    }
  }, [isConnected]);

  const connectConversation = () => {
    console.log('connectConversation');
    connection
      .start()
      .then(() => {
        console.log(
          'Connected to SignalR hub for Claimant_' +
            claimant?.cellPhone?.replace('-', '')
        );
        setisConnected(true);
      })
      .catch((err) => console.error('Error connecting to hub:', err));

    connection.on(
      'Claimant_' + claimant?.cellPhone?.replace('-', ''),
      (message) => {
        toast.info('New message: ' + message);
        getConversationTextList();
      }
    );
  };

  const disconnectConversation = () => {
    connection.stop().then(() => setisConnected(false));
  };

  const tableData = useMemo(() => data, [data]);

  const getBackGroundColor = (r: TextGridObj) => {
    if (r.status === 'New') {
      return 'cyan';
    }
  };

  const columnData: ColumnDef<TextGridObj>[] = [
    {
      id: 'claim',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div>
            {row.original.currentClaimNumber && (
              <Link to={`/claims/${row.original.currentClaimNumber}`}>
                <Button
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  className='button-icon-text'
                >
                  <FaEye /> Claim
                </Button>
              </Link>
            )}
          </div>
        );
      },
      maxSize: 100,
    },
    {
      header: 'Preview',
      accessorKey: 'body',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row }) => {
        return (
          <div>
            {/* <div className={`${getBackGroundColor(row.original)}`}>
              {row.original.status}
            </div> */}
            <div
              className={`${getBackGroundColor(row.original)}`}
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {row.original.body}
            </div>
          </div>
        );
      },
    },
    {
      header: 'Date Created',
      accessorKey: 'dateCreated',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Cell Phone',
      accessorKey: 'numberFrom',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'First Name',
      accessorKey: 'firstName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Last Name',
      accessorKey: 'lastName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claim #',
      accessorKey: 'currentClaimNumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];
  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onRowSelectionChange: setRowSelection,
  });

  const onSubmit = (values: TextLog) => {
    return TextLogApi.addOutgoingLog(values)
      .then((res) => {
        if (res.data.success) {
          formInstance.reset();
          getConversationTextList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error('Failed to send text');
      });
  };

  return (
    <div className='w-100 h-100 d-flex'>
      <div className='w-100 px-3'>
        <PaginatedTable
          table={table}
          columnResizeMode='onChange'
          showFilters={true}
          selectableRow={true}
          highlightRow={true}
        />
      </div>
      <div className={`${styles.letterSide} border p-2`}>
        <div className={`${styles.textTopInfo}`}>
          <div>
            Claimant Name: {claimant?.firstName} {claimant?.lastName}
          </div>
          <div>Claimant Phone #: {claimant?.cellPhone}</div>
        </div>
        {/* <div>Claim #: {claim?.claimNo}</div> */}
        <div
          className={`d-flex flex-column-reverse border w-100 overflow-auto ${styles.textBackground}`}
          style={{ height: '100%' }}
        >
          <div className='d-flex flex-column'>
            {conversationData.map((text) => {
              return text.direction === 'incoming-api' ? (
                <div className='d-flex flex-column'>
                  <div key={text.id} className={`${styles.fromMessage}`}>
                    <div className={`${styles.fromMessageBody}`}>
                      {text.body}
                    </div>
                  </div>
                  <div
                    key={'date-' + text.id}
                    className={`${styles.fromMessage}`}
                  >
                    <div className={`${styles.messageDate}`}>
                      {displayDateAndTimeLocal(text.dateCreated ?? '')}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='d-flex flex-column'>
                  <div key={text.id} className={`${styles.toMessage}`}>
                    <div className={`${styles.toMessageBody}`}>{text.body}</div>
                  </div>
                  <div
                    key={'date-' + text.id}
                    className={`${styles.toMessage}`}
                  >
                    <div className={`${styles.messageDate}`}>
                      {displayDateAndTimeLocal(text.dateCreated ?? '')}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <RFFForm
          onSubmit={onSubmit}
          initialValues={{
            numberTo: claimant?.cellPhone,
            firstName: claimant?.firstName,
            lastName: claimant?.lastName,
            claimantPhoneNumber: claimant?.cellPhone,
            body: '',
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            formInstance = form;
            return (
              <Form onSubmit={handleSubmit} className={`${styles.formSubmit}`}>
                <div className={`d-flex`}>
                  <div className={`w-100`}>
                    <Field
                      name='body'
                      label='Message'
                      rows={2}
                      component={FieldBSRenderTextArea}
                    />
                  </div>
                  <div className={styles.claimantFormButtonDiv}>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Send'
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
}
