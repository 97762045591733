import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { DeductibleBillingRequest } from '../ApiTypes/DeductibleBillingRequest';
import { PremiumAndLossSummaryRequest } from '../ApiTypes/PremiumAndLossSummaryRequest';
import { ReportRequest, ReportResponse } from '../ApiTypes/ReportRequest';
import { FileDownload } from '../Types/FileDownLoad';
import { DevExpressReportRequest } from '../ApiTypes/DevExpressReportRequest';

class ReportApi {
  static getCrystalReport(request: ReportRequest) {
    return axios.post<ReportResponse>(
      `${API_URL}/api/reports/crystal`,
      request,
      getAuthHeader()
    );
  }
  static getDxReport(request: DevExpressReportRequest) {
    return axios.post<ReportResponse>(
      `${API_URL}/api/reports/dx`,
      request,
      getAuthHeader(),
    );
  }
  static getDeductibleBilling(request: DeductibleBillingRequest) {
    return axios.post<ReportResponse>(
      `${API_URL}/api/deductiblebilling`,
      request,
      getAuthHeader()
    );
  }
  static getPremiumAndLossSummary(request: PremiumAndLossSummaryRequest) {
    return axios.post<FileDownload>(
      `${API_URL}/api/excel/premiumandloss`,
      request,
      getAuthHeader()
    );
  }
  static getFundAnalysis(request: PremiumAndLossSummaryRequest) {
    return axios.post<FileDownload>(
      `${API_URL}/api/excel/fundanalysis`,
      request,
      getAuthHeader()
    );
  }
  static getPremiumAnalysisSetup(treatyYear: number, insurCoId: number) {
    return axios.get<FileDownload>(
      `${API_URL}/api/excel/premiumanalysissetup?treatyyear=${treatyYear}&insurcoid=${insurCoId}`,
      getAuthHeader()
    );
  }
}

export default ReportApi;
