import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaColumns, FaSearch } from 'react-icons/fa';
import { Form as RFForm, Field } from 'react-final-form';
import { EmployerPolicyPanel } from '../../ApiTypes/EmployerPolicyPanel';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppSelector } from '../../Reducers/Store';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { WcPanel } from '../../ApiTypes/WcPanel';
import WcPanelApi from '../../Api/WcPanelApi';
import { toast } from 'react-toastify';
import { Employer } from '../../ApiTypes/Employer';
import EmployerApi from '../../Api/EmployerApi';
import { EmployerPolicy } from '../../ApiTypes/EmployerPolicy';
import { Location } from '../../ApiTypes/Location';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderFilePicker from '../Common/FieldBSRenderFilePicker';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import { FormApi } from 'final-form';
import BSRenderText from '../Common/BSRenderText';
import { format } from 'date-fns';
import styles from './index.module.css';
import { EmployerPolicyPanelAddUpdateRequest } from '../../ApiTypes/EmployerPolicyPanelAddUpdateRequest';
import { GeneratePanelRequest } from '../../ApiTypes/GeneratePanelRequest';

interface EmployerPolicyPanelFormType {
  accountNumber: string;
  employerId: number;
  policyId: number;
  panelId: number;
  effectiveDate: string;
  locationIds: number[];
  usePanelInfo: boolean;
  useUpdatedPanel: boolean;
  insertCompanyName: boolean;
  positionPage1: number | null;
  positionPage2: number | null;
  positionXpage1: number | null;
  positionXpage2: number | null;
  file: { file?: File; name?: string };
}

export default function WCPanelPolicyLink({
  show,
  setShow,
  employerPolicyPanel,
  wcPanelId,
  afterSubmit,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  employerPolicyPanel: EmployerPolicyPanel | null;
  wcPanelId?: number;
  afterSubmit: () => void;
}) {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  let formInstance: FormApi<
    EmployerPolicyPanelFormType,
    Partial<EmployerPolicyPanelFormType>
  >;
  const formRef: React.MutableRefObject<FormApi<
    EmployerPolicyPanelFormType,
    Partial<EmployerPolicyPanelFormType>
  > | null> = useRef(null);

  // useEffect(() => {
  //   init();
  // }, [wcPanelId, employerPolicyPanel]);

  useEffect(() => {
    if (show) {
      init();
    }
  }, [show]);

  const [wcPanel, setWcPanel] = useState<WcPanel | null>(null);
  // const [wcPanels, setWcPanels] = useState<WcPanel[]>([]);
  const [employers, setEmployers] = useState<Employer[]>([]);
  const [policies, setPolicies] = useState<EmployerPolicy[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);

  const [positionPage1, setPositionPage1] = useState<string>('0');
  const [positionPage2, setPositionPage2] = useState<string>('0');
  const [positionXPage1, setpositionXPage1] = useState<string>('0');
  const [positionXPage2, setpositionXPage2] = useState<string>('0');

  const init = () => {
    setEmployers([]);
    setPolicies([]);
    setLocations([]);
    const panelId = employerPolicyPanel
      ? employerPolicyPanel.panelId
      : wcPanelId;

    if (panelId) {
      getWcPanelById(panelId);
      // listWcPanels();
    }

    if (employerPolicyPanel?.policy?.employer?.accountNumber) {
      getEmployersForAccount(
        employerPolicyPanel?.policy?.employer?.accountNumber
      );
    }
    if (employerPolicyPanel?.policy?.employerId) {
      getPoliciesForEmployer(employerPolicyPanel?.policy?.employerId);
    }
    if (employerPolicyPanel?.policyId) {
      getLocationsByPolicyId(employerPolicyPanel?.policyId);
    }
  };

  const getWcPanelById = (panelId: number) => {
    if (panelId) {
      WcPanelApi.getWcPanelById(panelId)
        .then((res) => {
          setWcPanel(res.data);
          if (res.data.positionPage1) {
            setPositionPage1(`${res.data.positionPage1}`);
          }
          if (res.data.positionPage2) {
            setPositionPage2(`${res.data.positionPage2}`);
          }
          if (res.data.positionXpage1) {
            setpositionXPage1(`${res.data.positionXpage1}`);
          } else {
            setpositionXPage1(`125`);
          }
          if (res.data.positionXpage2) {
            setpositionXPage2(`${res.data.positionXpage2}`);
          } else {
            setpositionXPage2(`240`);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get Wc Panel');
        });
    }
  };

  const getEmployersForAccount = (accountNumber: string) => {
    if (accountNumber) {
      EmployerApi.getEmployerList({ accountNumber })
        .then((res) => {
          const sorted = [...res.data].sort((a, b) =>
            (a.name ?? '').localeCompare(b.name ?? '')
          );
          setEmployers(sorted);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get employer list');
        });
    }
  };

  const getPoliciesForEmployer = (employerId: number) => {
    if (employerId) {
      EmployerApi.getEmployerPolicies(employerId)
        .then((res) => {
          const sorted = [...res.data].sort(
            (a, b) => b.treatyYear - a.treatyYear
          );
          setPolicies(sorted);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get policies for employer');
        });
    }
  };

  const getLocationsByPolicyId = (policyId: number) => {
    if (policyId) {
      EmployerApi.getLocationsByPolicyId(policyId)
        .then((res) => {
          setLocations(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get locations for policy');
        });
    }
  };

  const getDisplayName = (loc: Location) => {
    let cityStateZip = '';
    if (loc.address) {
      cityStateZip = `${loc.address.city}, ${loc.address.state} ${loc.address.zip}`;
    }
    return `${loc.locationName ?? ''} ${cityStateZip}`;
  };

  const handleUsePanelInfoChange = (checked: boolean) => {
    if (checked) {
      formInstance.change('useUpdatedPanel', false);
      formInstance.change('insertCompanyName', true);
    }
  };
  const handleUseUpdatePanelChange = (checked: boolean) => {
    if (checked) {
      formInstance.change('usePanelInfo', false);
      formInstance.change('insertCompanyName', false);
    } else {
    }
  };

  const handleAddUpdatedPanel = async (file: { file: File; name: string }) => {
    const fd = new FormData();
    let newName = file.name.replace('/', '_');
    let fileToUpload = file.file;
    fileToUpload = new File([file.file!], `${file.name}`, {
      type: file.file!.type,
    });
    fd.append('name', newName);
    fd.append('file', fileToUpload);
    let docId = 0;
    await WcPanelApi.uploadWcPanelDoc(fd)
      .then((res) => {
        if (res.data.success) {
          docId = +res.data.affectedEntityIdentifier;
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to upload document');
      });
    return docId;
  };

  const generateDoc = async (
    wcPanel: WcPanel,
    insertCompanyName: boolean,
    usePanelInfo: boolean,
    useUpdatedPanel: boolean,
    file: { file?: File; name?: string }
  ) => {
    const request: WcPanel = {
      id: wcPanel.id,
      name: null,
      year: null,
      docId: null,
      createdBy: null,
      dateCreated: null,
      dateModified: null,
      notes: null,
      isDeleted: null,
      positionPage1: Number(positionPage1),
      positionPage2: Number(positionPage2),
      positionXpage1: Number(positionXPage1),
      positionXpage2: Number(positionXPage2),
      doc: null,
      employerPolicyPanels: [],
      tEmployerPolicies: [],
      wcpanelCoverages: [],
      wcpanelInsuranceCos: [],
    };
    let docId = 0;
    await WcPanelApi.updatePanelPositions(request)
      .then((res) => {
        if (res.data.success) {
        } else {
          toast.error('Generate Doc Error');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to Generate Doc');
      });
    const employerpanelId = employerPolicyPanel ? employerPolicyPanel.id : 0;
    const generateRequest: GeneratePanelRequest = {
      employerPanelId: employerpanelId,
      usePanelInfo: usePanelInfo,
      insertCompanyName: insertCompanyName,
      useUpdatedPanel: useUpdatedPanel,
    };
    if (generateRequest.employerPanelId > 0 && generateRequest.usePanelInfo) {
      await WcPanelApi.createEmployerPanel(generateRequest)
        .then((res) => {
          if (res.data > 0) {
            toast.success('Generate Doc Success');
            docId = res.data;
          } else {
            toast.error('Generate Doc Error');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to Generate Doc');
        });
    } else if (generateRequest.useUpdatedPanel && file) {
      docId = await handleAddUpdatedPanel({
        file: file.file!,
        name: file.name!,
      });
    }
    return docId;
  };

  const generateDocTest = () => {
    const panelId = employerPolicyPanel
      ? employerPolicyPanel.panelId
      : wcPanelId;
    const request: WcPanel = {
      id: panelId!,
      name: null,
      year: null,
      docId: null,
      createdBy: null,
      dateCreated: null,
      dateModified: null,
      notes: null,
      isDeleted: null,
      positionPage1: Number(positionPage1),
      positionPage2: Number(positionPage2),
      positionXpage1: Number(positionXPage1),
      positionXpage2: Number(positionXPage2),
      doc: null,
      employerPolicyPanels: [],
      tEmployerPolicies: [],
      wcpanelCoverages: [],
      wcpanelInsuranceCos: [],
    };

    WcPanelApi.updatePanelPositions(request)
      .then((res) => {
        if (res.data.success) {
          const employerpanelId = employerPolicyPanel
            ? employerPolicyPanel.id
            : 0;
          const generateRequest: GeneratePanelRequest = {
            employerPanelId: employerpanelId,
            usePanelInfo:
              formRef.current?.getFieldState('usePanelInfo')?.value ?? false,
            insertCompanyName:
              formRef.current?.getFieldState('insertCompanyName')?.value ??
              false,
            useUpdatedPanel:
              formRef.current?.getFieldState('useUpdatedPanel')?.value ?? false,
          };
          if (generateRequest.employerPanelId > 0) {
            WcPanelApi.createEmployerPanel(generateRequest)
              .then((res) => {
                if (res.data > 0) {
                  toast.success('Generate Doc Success');
                  window.open(
                    `/scandocs/${res.data}`,
                    `newwindow${res.data.toString()}`,
                    'width=1300,height=750'
                  );
                } else {
                  toast.error('Generate Doc Error');
                }
              })
              .catch((err) => {
                console.log(err);
                toast.error('Failed to Generate Doc');
              });
          }
        } else {
          toast.error('Generate Doc Error');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to Generate Doc');
      });
  };
  const onSubmit = async (values: EmployerPolicyPanelFormType) => {
    const now = format(new Date(), 'yyyy-MM-dd');
    const {
      policyId,
      panelId,
      locationIds,
      effectiveDate,
      insertCompanyName,
      usePanelInfo,
      useUpdatedPanel,
      file,
    } = values;
    // const wcPanel = wcPanels.find((x) => x.id === panelId);
    const employerPolicy = policies.find((x) => x.policyId === +policyId);
    if (!wcPanel || !employerPolicy) {
      return;
    }
    const dateAdded = now;
    const panelName = wcPanel?.name ?? '';

    const endDate = employerPolicy?.endDate ?? now;

    // const docId = await Promise.all(generateDoc(
    //   wcPanel,
    //   insertCompanyName,
    //   usePanelInfo,
    //   useUpdatedPanel,
    //   file
    // ));
    const docId = await generateDoc(
      wcPanel,
      insertCompanyName,
      usePanelInfo,
      useUpdatedPanel,
      file
    );
    // TODO
    // toast.info('Generate Doc, not implemented');

    const request: EmployerPolicyPanelAddUpdateRequest = {
      id: employerPolicyPanel?.id ?? 0,
      policyId: policyId,
      wcPanelId: panelId,
      dateAdded: dateAdded,
      docId: docId ?? 0,
      panelName: panelName,
      effectiveDate: effectiveDate,
      endDate: endDate,
      locationIds: locationIds,
    };

    if (employerPolicyPanel?.id) {
      await WcPanelApi.updateEmployerPolicyPanel(request)
        .then((res) => {
          if (res.data.success) {
            toast.success('Success');
            setShow(false);
            afterSubmit();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update Employer Policy Panel');
        });
    } else {
      await WcPanelApi.addEmployerPolicyToPanel(request)
        .then((res) => {
          if (res.data.success) {
            toast.success('Success');
            setShow(false);
            afterSubmit();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to add Employer Policy Panel');
        });
    }
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='WCPanelPolicyLink-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='WCPanelPolicyLink-modal'>
          <FaColumns className='pe-1' /> Panel Maintenance
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            keepDirtyOnReinitialize={true}
            onSubmit={onSubmit}
            initialValues={{
              accountNumber:
                employerPolicyPanel?.policy?.employer?.accountNumber ?? '',
              employerId: employerPolicyPanel?.policy?.employerId ?? 0,
              policyId: employerPolicyPanel?.policyId ?? 0,
              panelId: employerPolicyPanel?.panelId ?? wcPanelId ?? 0,
              effectiveDate: employerPolicyPanel?.effectiveDate ?? '',
              positionPage1: wcPanel?.positionPage1,
              positionPage2: wcPanel?.positionPage2,
              positionXpage1: wcPanel?.positionXpage1,
              positionXpage2: wcPanel?.positionXpage2,
              usePanelInfo: true,
              useUpdatedPanel: false,
              insertCompanyName: true,
              locationIds: (
                employerPolicyPanel?.employerPolicyPanelLocations ?? []
              ).map((x) => x.locationId),
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              formRef.current = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='accountNumber'
                    label='Account'
                    options={insuranceCompaniesForUser}
                    onChange={(e: any) => {
                      setPolicies([]);
                      setLocations([]);
                      getEmployersForAccount(e);
                    }}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option
                          key={o.accountnumber}
                          value={o.accountnumber ?? ''}
                        >
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='employerId'
                    label='Employer'
                    options={employers}
                    onChange={(e: any) => {
                      setLocations([]);
                      getPoliciesForEmployer(e);
                    }}
                    optionMethod={(options: Employer[]) =>
                      options.map((o) => (
                        <option key={o.employerId} value={o.employerId}>
                          {o.name}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='policyId'
                    label='Policy'
                    options={policies}
                    onChange={getLocationsByPolicyId}
                    optionMethod={(options: EmployerPolicy[]) =>
                      options.map((o) => (
                        <option key={o.policyId} value={o.policyId}>
                          {o.policyNumber}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <div className='mb-3'>
                    <fieldset>
                      <legend>Locations</legend>
                      {locations.map((d) => {
                        return (
                          <Field
                            key={d.locationId}
                            name='locationIds'
                            type='checkbox'
                            label={getDisplayName(d)}
                            checked={values.locationIds.includes(d.locationId)}
                            value={d.locationId}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                    </fieldset>
                  </div>
                  <BSRenderText
                    name='wcPanelName'
                    label='Panel'
                    type='text'
                    readOnly
                    value={wcPanel?.name ?? ''}
                  />
                  <div className='d-flex justify-content-between align-items-center gap1Rem pb-3'>
                    <Field
                      name='usePanelInfo'
                      type='checkbox'
                      label='Use Panel Info'
                      checked={values.usePanelInfo === true}
                      onCheckChange={handleUsePanelInfoChange}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='insertCompanyName'
                      type='checkbox'
                      label='Insert Company Name'
                      checked={values.insertCompanyName === true}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='useUpdatedPanel'
                      type='checkbox'
                      label='Use Updated Panel'
                      onCheckChange={handleUseUpdatePanelChange}
                      checked={values.useUpdatedPanel === true}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  {!values.usePanelInfo && (
                    <Field
                      name='file'
                      type='file'
                      component={FieldBSRenderFilePicker}
                    />
                  )}
                  <Field
                    name='effectiveDate'
                    label='Effective Date'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                    validate={requiredField}
                  />
                  <div>Company Name Position</div>
                  <div className='d-flex justify-content-between align-items-center gap1Rem'>
                    <div className='d-flex align-items-center gap1Rem w-50'>
                      <BSRenderText
                        name='positionXpage1'
                        label='Page 1 X'
                        type='number'
                        value={positionXPage1}
                        onChange={setpositionXPage1}
                      />
                      <BSRenderText
                        name='positionPage1'
                        label='Page 1 Y'
                        type='number'
                        value={positionPage1}
                        onChange={setPositionPage1}
                      />
                      <BSRenderText
                        name='positionXpage2'
                        label='Page 2 X'
                        type='number'
                        value={positionXPage2}
                        onChange={setpositionXPage2}
                      />
                      <BSRenderText
                        name='positionPage2'
                        label='Page 2 Y'
                        type='number'
                        value={positionPage2}
                        onChange={setPositionPage2}
                      />
                      <Button
                        variant='outline-primary'
                        size='sm'
                        type='button'
                        title='Preview'
                        onClick={() => {
                          toast.info('Generate Doc and preview');
                          generateDocTest();
                        }}
                      >
                        <FaSearch />
                      </Button>
                    </div>
                    <div>
                      <Button type='submit' variant='primary' size='sm'>
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
