import React, { useState, useEffect } from 'react';
import { Container, Modal, Form, Button } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaFilter } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import EmployerApi from '../../Api/EmployerApi';
import { Employer } from '../../ApiTypes/Employer';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';

import { useAppSelector } from '../../Reducers/Store';
import { FileDownload } from '../../Types/FileDownLoad';
import { parseDatesForServer } from '../../Utils';
import BSSelect from '../Common/BSSelect';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { getCrystalReport, getDxReport } from '../DocViewModal/useDocViewModal';

import styles from './index.module.css';
import {
  DevExpressReportRequest,
  DxAvailableReports,
  ExportTypes,
} from '../../ApiTypes/DevExpressReportRequest';
import { format } from 'date-fns';

export default function DxReportRequestModal({
  show,
  setShow,
  reportEnumValue,
  setShowDocViewModal,
  setFileDownload,
}: {
  reportEnumValue: number;
  show: boolean;
  setShow: (show: boolean) => void;
  setShowDocViewModal: (s: boolean) => void;
  setFileDownload: (f: FileDownload | null) => void;
}) {
  const { claimNumber } = useParams();
  const { insuranceCompanies } = useAppSelector((state) => state.reference);
  const { userName } = useAppSelector((state) => state.user);

  const [employers, setEmployers] = useState<Employer[]>([]);
  const [fieldsToShow, setFieldsToShow] = useState<string[]>([]);

  useEffect(() => {
    getFieldsToShow();
  }, [reportEnumValue]);

  const getFieldsToShow = () => {
    switch (reportEnumValue) {
      case DxAvailableReports.rptClaimStatusChange:
        setFieldsToShow(['accountNumber', 'fromDate', 'toDate']);
        break;
      case DxAvailableReports.rptOutstandingChecks:
        setFieldsToShow(['accountNumber']);
        break;
      case DxAvailableReports.rptLossRun:
        setFieldsToShow([
          'accountNumber',
          'startYear',
          'endYear',
          'employerId',
          'openOnly',
          'external',
        ]);
        break;
      case DxAvailableReports.rptTopInjuries:
        setFieldsToShow([
          'accountNumber',
          'employerId',
          'fromDate',
          'toDate',
          'topInjuriesReportType',
          'topInjuriesReportFilter',
        ]);
        break;
      case DxAvailableReports.rpt17Line:
        setFieldsToShow(['insurCoId', 'startYear', 'endYear', 'asOfDate']);
        break;
      case DxAvailableReports.rptAGTReserveDifference:
        setFieldsToShow([
          'accountNumber',
          'startYear',
          'endYear',
          'minReserveAmount',
        ]);
        break;
      case DxAvailableReports.rptLossSummary:
        setFieldsToShow(['accountNumber', 'incidentOnly', 'useEOMData']);
        break;
      case DxAvailableReports.rptClaimsCountByAdjuster:
        setFieldsToShow(['insurCoId']);
        break;

      case DxAvailableReports.rptClaimSummaryAT:
        sendRequestImmediately(reportEnumValue, '063');
        break;

      default:
        setFieldsToShow([]);
        break;
    }
  };

  const sendRequestImmediately = (
    reportValue: number,
    accountNumber?: string
  ) => {
    const request: DevExpressReportRequest = {
      accountNumber: accountNumber ?? '',
      claimNumber: '',
      employerId: null,
      fromDate: null,
      asOfDate: null,
      insurCoId: 0,
      startYear: 0,
      endYear: 0,
      report: reportValue,
      severity: '',
      status: '',
      toDate: null,
      external: false,
      openOnly: false,
      onlyClearedChecks: false,
      userId: '',
      searchText: '',
      minReserveAmount: 0,
      caseManagementId: 0,
      letterId: 0,
      policyId: 0,
      reserveWorksheetId: 0,
      eDITransactionId: 0,
      keyDocRequestId: 0,
      topInjuriesReportType: 0,
      topInjuriesReportFilter: '',
      workStatusId: 0,
      maskSSN: false,
      wC4Id: 0,
      incidentOnly: false,
      useEOMData: true,
      useClaimDataForWc1: false,
      showAdjusterInfo: true,
      treatyYear: 0,
      exportType: ExportTypes.PDF,
      nCMReportType: 1,
    };
    getDxReport(request, setFileDownload, setShowDocViewModal);
    setShow(false);
    setEmployers([]);
  };

  const onSubmit = (values: DevExpressReportRequest) => {
    const request: DevExpressReportRequest = {
      accountNumber: values.accountNumber ?? '',
      claimNumber: values.claimNumber ?? '',
      employerId: values.employerId ?? null,
      fromDate: values.fromDate ?? null,
      asOfDate: values.asOfDate ?? null,
      insurCoId: values.insurCoId ?? 0,
      startYear: values.startYear ?? 0,
      endYear: values.endYear ?? 0,
      report: reportEnumValue,
      severity: values.severity ?? '',
      status: values.status ?? '',
      toDate: values.toDate ?? null,
      external: values.external ?? false,
      openOnly: values.openOnly ?? false,
      userId: values.userId ?? '',
      minReserveAmount: values.minReserveAmount ?? 0,
      caseManagementId: values.caseManagementId ?? 0,
      letterId: values.letterId ?? 0,
      policyId: values.policyId ?? 0,
      reserveWorksheetId: values.reserveWorksheetId ?? 0,
      eDITransactionId: values.eDITransactionId ?? 0,
      keyDocRequestId: values.keyDocRequestId ?? 0,
      searchText: values.searchText ?? '',
      onlyClearedChecks: values.onlyClearedChecks ?? false,
      topInjuriesReportType: values.topInjuriesReportType ?? 0,
      topInjuriesReportFilter: values.topInjuriesReportFilter ?? '',
      workStatusId: values.workStatusId ?? 0,
      wC4Id: values.wC4Id ?? 0,
      maskSSN: values.maskSSN ?? false,
      incidentOnly: values.incidentOnly ?? false,
      useEOMData: values.useEOMData ?? true,
      useClaimDataForWc1: false,
      showAdjusterInfo: true,
      treatyYear: 0,
      exportType: values.exportType ? +values.exportType : ExportTypes.PDF,
      nCMReportType: 1,
    };

    if (reportEnumValue === DxAvailableReports.rptOutstandingChecks) {
      request.toDate = format(new Date(), 'yyyy-MM-dd');
      request.fromDate = '1999-01-01';
    }

    getDxReport(request, setFileDownload, setShowDocViewModal);
    //setShow(false);
    //setEmployers([]);
  };

  const getEmployers = (accountNumber: string) => {
    EmployerApi.getEmployerList({ accountNumber })
      .then((res) => {
        setEmployers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        setEmployers([]);
      }}
      aria-labelledby='Report-Request-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Report-Request-modal'>
          <FaFilter className='pe-1' /> Report Options
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              claimNumber,
              useEOMData: true,
              incidentOnly: false,
              exportType: ExportTypes.PDF,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {(fieldsToShow.includes('accountNumber') ||
                    fieldsToShow.includes('employerId')) && (
                    <Field
                      name='accountNumber'
                      label='Account'
                      options={[
                        ...insuranceCompanies
                          .map((x) => x)
                          .sort(
                            (a, b) => +a.accountnumber! - +b.accountnumber!
                          ),
                      ]}
                      optionMethod={(options: InsuranceCompany[]) =>
                        options.map((o) => (
                          <option
                            key={o.accountnumber!}
                            value={o.accountnumber!}
                          >
                            {`${o.accountnumber} - ${o.shortname}`}
                          </option>
                        ))
                      }
                      onChange={getEmployers}
                      component={FieldBSRenderSelect}
                    />
                  )}
                  {fieldsToShow.includes('insurCoId') && (
                    <Field
                      name='insurCoId'
                      label='Insurance Company'
                      options={[
                        ...insuranceCompanies
                          .map((x) => x)
                          .sort(
                            (a, b) => +a.accountnumber! - +b.accountnumber!
                          ),
                      ]}
                      optionMethod={(options: InsuranceCompany[]) =>
                        options.map((o) => (
                          <option key={o.insurcoid!} value={o.insurcoid!}>
                            {`${o.accountnumber} - ${o.shortname}`}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                  )}
                  {fieldsToShow.includes('employerId') && (
                    <Field
                      name='employerId'
                      label='Employer'
                      options={employers}
                      optionMethod={(options: Employer[]) =>
                        options.map((o) => (
                          <option key={o.employerId} value={o.employerId}>
                            {o.name}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                  )}
                  {fieldsToShow.includes('claimNumber') && (
                    <Field
                      name='claimNumber'
                      type='text'
                      label='Claim #'
                      component={FieldBSRenderText}
                    />
                  )}
                  {fieldsToShow.includes('asOfDate') && (
                    <Field
                      name='asOfDate'
                      type='text'
                      label='As Of Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  )}
                  {fieldsToShow.includes('fromDate') && (
                    <Field
                      name='fromDate'
                      type='text'
                      label='From Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  )}
                  {fieldsToShow.includes('toDate') && (
                    <Field
                      name='toDate'
                      type='text'
                      label='To Date'
                      defaultValue={format(new Date(), 'yyyy-MM-dd')}
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  )}
                  {fieldsToShow.includes('startYear') && (
                    <Field
                      name='startYear'
                      type='text'
                      label='Start Year'
                      component={FieldBSRenderText}
                    />
                  )}
                  {fieldsToShow.includes('endYear') && (
                    <Field
                      name='endYear'
                      type='text'
                      label='End Year'
                      component={FieldBSRenderText}
                    />
                  )}
                  {fieldsToShow.includes('userId') && (
                    <Field
                      name='userId'
                      type='text'
                      label='User Id'
                      component={FieldBSRenderText}
                    />
                  )}
                  {fieldsToShow.includes('searchText') && (
                    <Field
                      name='searchText'
                      type='text'
                      label='Search text'
                      component={FieldBSRenderText}
                    />
                  )}
                  {fieldsToShow.includes('topInjuriesReportType') && (
                    <Field
                      name='topInjuriesReportType'
                      label='Injuries Report Type'
                      options={[
                        { label: 'Body  Part', value: 0 },
                        { label: 'Cause', value: 1 },
                        { label: 'Result', value: 2 },
                      ]}
                      optionMethod={(
                        options: { label: string; value: number }[]
                      ) =>
                        options.map((o) => (
                          <option key={o.value} value={o.value}>
                            {o.label}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                  )}
                  {fieldsToShow.includes('topInjuriesReportFilter') && (
                    <Field
                      name='topInjuriesReportFilter'
                      type='text'
                      label='Injuries Report Filter'
                      component={FieldBSRenderText}
                    />
                  )}
                  {fieldsToShow.includes('minReserveAmount') && (
                    <Field
                      name='minReserveAmount'
                      type='text'
                      label='Min Reserve Amount'
                      component={FieldBSRenderText}
                    />
                  )}
                  {fieldsToShow.includes('openOnly') && (
                    <Field
                      name='openOnly'
                      type='checkbox'
                      label='Open Only'
                      checked={values.openOnly == true}
                      component={FieldBSRenderCheckbox}
                    />
                  )}
                  {fieldsToShow.includes('external') && (
                    <Field
                      name='external'
                      type='checkbox'
                      label='External'
                      checked={values.external == true}
                      component={FieldBSRenderCheckbox}
                    />
                  )}
                  {fieldsToShow.includes('onlyClearedChecks') && (
                    <Field
                      name='onlyClearedChecks'
                      type='checkbox'
                      label='Only Cleared Checks'
                      checked={values.onlyClearedChecks == true}
                      component={FieldBSRenderCheckbox}
                    />
                  )}

                  {fieldsToShow.includes('incidentOnly') && (
                    <Field
                      name='incidentOnly'
                      type='checkbox'
                      label='Include Incident Only'
                      checked={values.incidentOnly == true}
                      component={FieldBSRenderCheckbox}
                    />
                  )}
                  {fieldsToShow.includes('useEOMData') && (
                    <Field
                      name='useEOMData'
                      type='checkbox'
                      label='Use EOM Data'
                      checked={values.useEOMData == true}
                      component={FieldBSRenderCheckbox}
                    />
                  )}
                  <div className='d-flex justify-content-around'>
                    {fieldsToShow.includes('severity') && (
                      <fieldset>
                        <legend>Severity</legend>
                        <Field
                          name='severity'
                          type='radio'
                          label='Any'
                          value='Any'
                          checked={values.severity === 'Any'}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='severity'
                          type='radio'
                          label='LT'
                          value='LT'
                          checked={values.severity === 'LT'}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='severity'
                          type='radio'
                          label='MO'
                          value='MO'
                          checked={values.severity === 'MO'}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='severity'
                          type='radio'
                          label='AM'
                          value='AM'
                          checked={values.severity === 'AM'}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='severity'
                          type='radio'
                          label='MO/AM'
                          value='MO_AM'
                          checked={values.severity === 'MO_AM'}
                          component={FieldBSRenderCheckbox}
                        />
                      </fieldset>
                    )}
                    {fieldsToShow.includes('status') && (
                      <fieldset>
                        <legend>Status</legend>
                        <Field
                          name='status'
                          type='radio'
                          label='Any'
                          value='Any'
                          checked={values.status === 'Any'}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='status'
                          type='radio'
                          label='Open/Re-Open'
                          value='Open'
                          checked={values.status === 'Open'}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='status'
                          type='radio'
                          label='Closed'
                          value='Closed'
                          checked={values.status === 'Closed'}
                          component={FieldBSRenderCheckbox}
                        />
                      </fieldset>
                    )}
                  </div>
                  <div className='d-flex justify-content-around'>
                    <fieldset className='d-flex justify-content-center gap1Rem'>
                      <legend>File Type</legend>
                      <Field
                        name='exportType'
                        type='radio'
                        label='PDF'
                        value={ExportTypes.PDF}
                        checked={+values.exportType === ExportTypes.PDF}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='exportType'
                        type='radio'
                        label='XLS'
                        value={ExportTypes.XLS}
                        checked={+values.exportType === ExportTypes.XLS}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='exportType'
                        type='radio'
                        label='WORD'
                        value={ExportTypes.WORD}
                        checked={+values.exportType === ExportTypes.WORD}
                        component={FieldBSRenderCheckbox}
                      />
                    </fieldset>
                  </div>
                  <div className='d-flex justify-content-center align-items-center gap1Rem my-3'>
                    <Button type='submit' variant='primary' size='sm'>
                      Submit
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                        setEmployers([]);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
