import { useState, useEffect } from 'react';
import TextMessagesTable from './TextMessagesTable';
import { TextGridObj } from '../../ApiTypes/TextGridObj';
import TextLogApi from '../../Api/TextLogApi';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { BiSolidConversation } from 'react-icons/bi';
import { toast, ToastContentProps } from 'react-toastify';
import Loading from '../Common/Loading';
import AppLayout from '../AppLayout/AppLayout';
import { Button, Container } from 'react-bootstrap';
export default function TextMessages() {
  const [data, setData] = useState<TextGridObj[]>([]);
  const [loading, setloading] = useState<boolean>(false);

  useEffect(() => {
    getTextLogs();
  }, []);

  const getTextLogs = () => {
    setloading(true);
    TextLogApi.getLogs()
      .then((res) => {
        setData(res.data);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  return (
    <AppLayout>
      <>{loading && <Loading />}</>
      <Container fluid className={`${styles.pageHeight}`}>
        <div className={`${styles.pageTop} bg-light`}>
          <div className='ps-3 d-flex'>
            <BiSolidConversation className='fs-1 text-primary mt-1' />
            <div className='ms-3'>
              <h1>Text Messages</h1>
            </div>
          </div>
          <div>
            <Button
              type='button'
              onClick={getTextLogs}
              size='sm'
              variant='primary'
            >
              Refresh List
            </Button>
          </div>
        </div>
        <div className='h-100'>
          <TextMessagesTable data={data} getTextLogs={getTextLogs} />
        </div>
      </Container>
    </AppLayout>
  );
}
