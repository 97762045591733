import React from 'react';
import { Container, Modal, Spinner } from 'react-bootstrap';
import { FaArrowRight, FaSearch } from 'react-icons/fa';
import { Form as RFFForm, Field } from 'react-final-form';
import { Button, Form } from 'react-bootstrap';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import styles from './index.module.css';
import { State } from '../../ApiTypes/State';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { Claimant } from '../../ApiTypes/Claimant';
import { FormApi } from 'final-form';
import ClaimantApi from '../../Api/ClaimantApi';
import { toast } from 'react-toastify';
import { fetchFullClaimByClaimNumber } from '../../Actions/ClaimActions';
import { useParams } from 'react-router-dom';
import { updateCurrentClaimantInReducer } from '../../Actions/CurrentClaimActions';
import { requiredField, zipLength } from '../../Utils/FieldValidation';
import { formatSSN, removeNonDigits } from '../../Utils/InputFormatters';
import { ClaimTabsEnum } from '../../ApiTypes/ClaimTypeTab';

interface ClaimantFormValues extends Claimant {
  achBankAccountNumberConfirm: string;
}

export default function ClaimantForm({
  show,
  setShow,
  claimant,
}: {
  show: boolean;
  setShow: (value: boolean) => void;
  claimant?: Claimant;
}) {
  const { claimNumber } = useParams();
  const dispatch = useAppDispatch();
  const { states } = useAppSelector((state) => state.reference);
  const { claimTypeConfiguration } = useAppSelector(
    (state) => state.currentClaimReducer
  );
  const { userModel } = useAppSelector((state) => state.user);
  let formInstance: FormApi<ClaimantFormValues, Partial<ClaimantFormValues>>;

  const onSubmit = (values: ClaimantFormValues) => {
    console.log(values);
    if (!claimant) {
      return createClaimant(values);
    }
    return updateClaimant(values);
  };

  const createClaimant = (values: ClaimantFormValues) => {
    const main = values.mainAddress?.address1
      ? { ...values.mainAddress, ownerTable: 't_claimant' }
      : null;
    const payment = values.paymentAddress?.address1
      ? { ...values.paymentAddress, ownerTable: 't_claimant' }
      : null;
    var newClaimant = { ...values, mainAddress: main, paymentAddress: payment };
    return ClaimantApi.createClaimant(newClaimant)
      .then((res) => {
        if (res.data.success) {
          ClaimantApi.getClaimantById(+res.data.affectedEntityIdentifier)
            .then((resp) => {
              if (resp.data) {
                dispatch(updateCurrentClaimantInReducer(resp.data));
              }
              setShow(false);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failure to create Claimant');
      });
  };
  const updateClaimant = (values: ClaimantFormValues) => {
    const main = values.mainAddress?.address1
      ? {
          ...values.mainAddress,
          ownerTable: 't_claimant',
          ownerId: values.claimantId,
        }
      : null;
    const payment = values.paymentAddress?.address1
      ? {
          ...values.paymentAddress,
          ownerTable: 't_claimant',
          ownerId: values.claimantId,
        }
      : null;
    var newClaimant = { ...values, mainAddress: main, paymentAddress: payment };
    return ClaimantApi.updateClaimant(newClaimant)
      .then((res) => {
        if (res.data.success) {
          // if (claimNumber) {
          ClaimantApi.getClaimantById(claimant?.claimantId!)
            .then((res) => {
              if (res.data) {
                dispatch(updateCurrentClaimantInReducer(res.data));
              }
              setShow(false);
            })
            .catch((err) => {
              console.log(err);
            });
          // }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failure to update Claimant');
      });
  };

  const changeIfExists = (field: keyof ClaimantFormValues, value: any) => {
    if (value) {
      formInstance.change(field, value);
    }
  };

  const copyAddress = (values: ClaimantFormValues) => {
    changeIfExists(
      'paymentAddress.addressName' as keyof ClaimantFormValues,
      values.mainAddress?.addressName
    );
    changeIfExists(
      'paymentAddress.address1' as keyof ClaimantFormValues,
      values.mainAddress?.address1
    );
    changeIfExists(
      'paymentAddress.address2' as keyof ClaimantFormValues,
      values.mainAddress?.address2
    );
    changeIfExists(
      'paymentAddress.city' as keyof ClaimantFormValues,
      values.mainAddress?.city
    );
    changeIfExists(
      'paymentAddress.state' as keyof ClaimantFormValues,
      values.mainAddress?.state
    );
    changeIfExists(
      'paymentAddress.zip' as keyof ClaimantFormValues,
      values.mainAddress?.zip
    );
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => setShow(false)}
      dialogClassName={styles.largeModal}
      aria-labelledby='Claimant-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Claim-Form-modal'>
          <FaSearch className='pe-1' /> Claimant
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={claimant ? claimant : {}}
            validate={(values) => {
              const errors: {
                [Property in keyof ClaimantFormValues]?: string;
              } = {};

              if (
                values.enableAch &&
                values.achBankAccountNumber !==
                  values.achBankAccountNumberConfirm
              ) {
                errors.achBankAccountNumber = 'Account Numbers Do Not Match!';
                errors.achBankAccountNumberConfirm =
                  'Account Numbers Do Not Match!';
              }

              if (values.enableAch && !values.achRoutingNumber) {
                errors.achRoutingNumber = 'Required';
              }
              if (values.enableAch && !values.achBankAccountNumber) {
                errors.achBankAccountNumber = 'Required';
              }
              if (values.enableAch && !values.achBankAccountNumberConfirm) {
                errors.achBankAccountNumberConfirm = 'Required';
              }
              if (!values.sex) {
                errors.sex = 'Required';
              }

              return errors;
            }}
            render={({ handleSubmit, form, values, pristine, submitting }) => {
              formInstance = form;
              const sexFieldState = form.getFieldState(
                'sex' as keyof ClaimantFormValues
              )!;
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={`${styles.topGrid}`}>
                    <Field
                      name='lastName'
                      type='text'
                      label='Last Name'
                      validate={requiredField}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='firstName'
                      type='text'
                      label='First Name'
                      component={FieldBSRenderText}
                      validate={requiredField}
                    />
                    <Field
                      name='middleName'
                      type='text'
                      label='Middle Name'
                      component={FieldBSRenderText}
                    />
                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.ClaimantForm &&
                        f.fieldName === 'employmentDate'
                    )?.visible && (
                      <Field
                        name='employmentDate'
                        type='text'
                        label={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.ClaimantForm &&
                              f.fieldName === 'employmentDate'
                          )?.labelName ?? 'Date of Hire'
                        }
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                    )}
                    <Field
                      name='ssn'
                      type='text'
                      label='SSN'
                      parse={removeNonDigits}
                      format={formatSSN}
                      validate={requiredField}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='dob'
                      type='text'
                      label='DOB'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='homePhone'
                      type='text'
                      label='Home Phone'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='workPhone'
                      type='text'
                      label='Work Phone'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='cellPhone'
                      type='text'
                      label='Cell Phone'
                      component={FieldBSRenderText}
                    />

                    <Field
                      name='emailAddress'
                      type='text'
                      label='Email'
                      component={FieldBSRenderText}
                    />

                    <div
                      className={`d-flex justify-content-center ${styles.flexGap1rem}`}
                      style={{ height: '80px' }}
                    >
                      <div>
                        <div
                          className={`d-flex ${styles.address3}`}
                          style={
                            sexFieldState?.submitFailed &&
                            sexFieldState?.invalid
                              ? {
                                  border: '1px solid red',
                                  borderRadius: '.25rem',
                                  marginTop: '19px',
                                  padding: '5px',
                                }
                              : {
                                  marginTop: '25px',
                                }
                          }
                        >
                          <Field
                            name='sex'
                            type='radio'
                            label='Male'
                            value='M'
                            checked={values?.sex === 'M'}
                            component={FieldBSRenderCheckbox}
                            Required={requiredField}
                          />
                          <Field
                            name='sex'
                            type='radio'
                            label='Female'
                            value='F'
                            checked={values.sex === 'F'}
                            component={FieldBSRenderCheckbox}
                            Required={requiredField}
                          />
                        </div>
                        <span className='Common_fieldError__vTcSD text-danger'>
                          {sexFieldState?.submitFailed && sexFieldState?.invalid
                            ? 'Required'
                            : ''}
                        </span>
                      </div>
                    </div>

                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.ClaimantForm &&
                        f.fieldName === 'enableMobileAccess'
                    )?.visible && (
                      <Field
                        name='enableMobileAccess'
                        type='checkbox'
                        label={
                          claimTypeConfiguration?.find(
                            (f) =>
                              f.claimTabId === ClaimTabsEnum.ClaimantForm &&
                              f.fieldName === 'enableMobileAccess'
                          )?.labelName ?? 'Enable Mobile App Access'
                        }
                        defaultChecked={false}
                        component={FieldBSRenderCheckbox}
                      />
                    )}

                    {claimTypeConfiguration?.find(
                      (f) =>
                        f.claimTabId === ClaimTabsEnum.ClaimantForm &&
                        f.fieldName === 'enableAch'
                    )?.visible && (
                      <div className={`${styles.enableAchGrid}`}>
                        <Field
                          name='enableAch'
                          type='checkbox'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.ClaimantForm &&
                                f.fieldName === 'enableAch'
                            )?.labelName ?? 'ACH'
                          }
                          checked={values.enableAch}
                          component={FieldBSRenderCheckbox}
                          disabled={!userModel?.user?.isManageAch}
                        />
                        <Field
                          name='achRoutingNumber'
                          type='text'
                          label='Routing Number'
                          disabled={!!!values.enableAch}
                          component={FieldBSRenderText}
                        />
                        <Field
                          name='achBankAccountNumber'
                          type='text'
                          label='Account Number'
                          disabled={!!!values.enableAch}
                          component={FieldBSRenderText}
                        />
                        <Field
                          name='achBankAccountNumberConfirm'
                          type='text'
                          label='Confirm Account Number'
                          disabled={!!!values.enableAch}
                          component={FieldBSRenderText}
                        />
                      </div>
                    )}
                  </div>

                  <div className='d-flex justify-content-end'>
                    <div className={styles.width33}>
                      <Field
                        name='usePaymentAddress'
                        type='checkbox'
                        label='Use Alternative Mailing Address'
                        defaultChecked={false}
                        component={FieldBSRenderCheckbox}
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <div className={styles.width45}>
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.ClaimantForm &&
                          f.fieldName === 'addressName'
                      )?.visible && (
                        <Field
                          name='mainAddress.addressName'
                          type='text'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.ClaimantForm &&
                                f.fieldName === 'addressName'
                            )?.labelName ?? 'Address Name'
                          }
                          component={FieldBSRenderText}
                        />
                      )}
                      <Field
                        name='mainAddress.address1'
                        type='text'
                        label='Address 1'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='mainAddress.address2'
                        type='text'
                        label='Address 2'
                        component={FieldBSRenderText}
                      />
                      <div
                        className={`d-flex ${styles.address3} ${styles.address3children}`}
                      >
                        <Field
                          name='mainAddress.city'
                          type='text'
                          label='City'
                          component={FieldBSRenderText}
                        />
                        <Field
                          name='mainAddress.state'
                          label='State'
                          options={states}
                          optionMethod={(options: State[]) =>
                            options.map((o) => (
                              <option key={o.stateAbbr} value={o.stateAbbr}>
                                {o.stateAbbr}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelect}
                        />
                        <Field
                          name='mainAddress.zip'
                          type='text'
                          label='Zip'
                          validate={zipLength}
                          component={FieldBSRenderText}
                          maxLength={5}
                        />
                      </div>
                    </div>
                    <div>
                      <Button
                        type='button'
                        size='sm'
                        variant='secondary'
                        onClick={() => copyAddress(values)}
                      >
                        Copy <FaArrowRight />
                      </Button>
                    </div>
                    <div className={styles.width45}>
                      {claimTypeConfiguration?.find(
                        (f) =>
                          f.claimTabId === ClaimTabsEnum.ClaimantForm &&
                          f.fieldName === 'addressName'
                      )?.visible && (
                        <Field
                          name='paymentAddress.addressName'
                          type='text'
                          label={
                            claimTypeConfiguration?.find(
                              (f) =>
                                f.claimTabId === ClaimTabsEnum.ClaimantForm &&
                                f.fieldName === 'addressName'
                            )?.labelName ?? 'Address Name'
                          }
                          component={FieldBSRenderText}
                        />
                      )}
                      <Field
                        name='paymentAddress.address1'
                        type='text'
                        label='Address 1'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='paymentAddress.address2'
                        type='text'
                        label='Address 2'
                        component={FieldBSRenderText}
                      />
                      <div
                        className={`d-flex ${styles.address3} ${styles.address3children}`}
                      >
                        <Field
                          name='paymentAddress.city'
                          type='text'
                          label='City'
                          component={FieldBSRenderText}
                        />
                        <Field
                          name='paymentAddress.state'
                          label='State'
                          options={states}
                          optionMethod={(options: State[]) =>
                            options.map((o) => (
                              <option key={o.stateAbbr} value={o.stateAbbr}>
                                {o.stateAbbr}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelect}
                        />
                        <Field
                          name='paymentAddress.zip'
                          type='text'
                          label='Zip'
                          validate={zipLength}
                          component={FieldBSRenderText}
                          maxLength={5}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.claimantFormButtonDiv}>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                    <Button
                      type='button'
                      size='sm'
                      variant='secondary'
                      onClick={() => {
                        form.reset();
                        form.restart();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
