import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as signalR from '@microsoft/signalr';
import TextLogApi from '../../Api/TextLogApi';
import { TextLog } from '../../ApiTypes/TextLog';
import { Field, Form as RFFForm } from 'react-final-form';
import { displayDateAndTimeLocal } from '../../Utils';
import styles from './index.module.css';
import { Claimant } from '../../ApiTypes/Claimant';
import { Button, Form, Spinner } from 'react-bootstrap';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import { FormApi } from 'final-form';
import ClaimantApi from '../../Api/ClaimantApi';

const connection = new signalR.HubConnectionBuilder()
  .withUrl('https://gasclaimsapi.georgia-admin.com/chatHub', {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
  })
  .build();

export default function TextConversationPage() {
  let { id } = useParams();
  const [claimant, setclaimant] = useState<Claimant | null>(null);
  const [data, setData] = useState<TextLog[]>([]);
  let formInstance: FormApi<TextLog>;

  const connectConversation = () => {
    console.log('connectConversation');
    connection
      .start()
      .then(() => {
        console.log(
          'Connected to SignalR hub for Claimant_' +
            claimant?.cellPhone?.replace('-', '')
        );
        // setisConnected(true);
      })
      .catch((err) => console.error('Error connecting to hub:', err));

    connection.on(
      'Claimant_' + claimant?.cellPhone?.replace('-', ''),
      (message) => {
        toast.info('New message: ' + message);
        getConversationTextList();
      }
    );
  };

  const disconnectConversation = () => {
    connection.stop();
  };
  const getConversationTextList = () => {
    if (claimant && claimant.cellPhone) {
      TextLogApi.getConversationTextList(claimant?.cellPhone?.replace('-', ''))
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setData([]);
    }
  };

  const getClaimant = (claimantId: number) => {
    ClaimantApi.getClaimantById(claimantId)
      .then((res) => {
        setclaimant(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get claimant');
      });
  };

  useEffect(() => {
    disconnectConversation();
    if (id) {
      getClaimant(Number(id));
    }
  }, [id]);

  useEffect(() => {
    if (claimant) {
      connectConversation();
      getConversationTextList();
    }
  }, [claimant]);

  const onSubmit = (values: TextLog) => {
    return TextLogApi.addOutgoingLog(values)
      .then((res) => {
        if (res.data.success) {
          formInstance.reset();
          getConversationTextList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error('Failed to send text');
      });
  };

  return (
    <div className={`px-2`}>
      <div className={`${styles.textTopInfo}`}>
        <div>
          Claimant Name: {claimant?.firstName} {claimant?.lastName}
        </div>
        <div>Claimant Phone #: {claimant?.cellPhone}</div>
      </div>
      <div
        className={`d-flex flex-column-reverse border w-100 overflow-auto ${styles.textBackground}`}
        style={{ height: '345px' }}
      >
        <div className='d-flex flex-column'>
          {data.map((text) => {
            return text.direction === 'incoming-api' ? (
              <div key={text.id} className='d-flex flex-column'>
                <div className={`${styles.fromMessage}`}>
                  <div className={`${styles.fromMessageBody}`}>{text.body}</div>
                </div>
                <div
                  key={'date-' + text.id}
                  className={`${styles.fromMessage}`}
                >
                  <div className={`${styles.messageDate}`}>
                    {displayDateAndTimeLocal(text.dateCreated ?? '')}
                  </div>
                </div>
              </div>
            ) : (
              <div key={text.id} className='d-flex flex-column'>
                <div key={text.id} className={`${styles.toMessage}`}>
                  <div className={`${styles.toMessageBody}`}>{text.body}</div>
                </div>
                <div key={'date-' + text.id} className={`${styles.toMessage}`}>
                  <div className={`${styles.messageDate}`}>
                    {displayDateAndTimeLocal(text.dateCreated ?? '')}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <RFFForm
        onSubmit={onSubmit}
        initialValues={{
          numberTo: claimant?.cellPhone,
          firstName: claimant?.firstName,
          lastName: claimant?.lastName,
          claimantPhoneNumber: claimant?.cellPhone,
          body: '',
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          formInstance = form;
          return (
            <Form onSubmit={handleSubmit} className={`${styles.formSubmit}`}>
              <div className={`d-flex`}>
                <div className={`w-100`}>
                  <Field
                    name='body'
                    label='Message'
                    rows={2}
                    component={FieldBSRenderTextArea}
                  />
                </div>
                <div className={styles.claimantFormButtonDiv}>
                  <Button type='submit' size='sm' variant='primary'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Send'
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
}
