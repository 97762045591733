import { format, isDate, intervalToDuration, Duration, min } from 'date-fns';
import saveAs from 'file-saver';
import { Address } from '../ApiTypes/Address';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
// import { Employer } from "../ApiTypes/Employer";

export const formatDateInputs = (
  value: string,
  startOfDay: boolean | null,
  endOfDay: boolean | null
) => {
  const dateFormat2 = RegExp(/\d{4}-\d{2}-\d{2}/);
  let input = value || '';

  // if seeding an initial value that is not "MM/dd/yyyy"
  if (input && input.includes('T')) {
    const [dParts, tParts] = input.split('T');
    const [year, month, day] = dParts.split('-');
    input = `${month}/${day}/${year}`;
  } else if (input && input.includes(' ') && input.includes('/')) {
    const [dParts, tParts, miscParts] = input.split(' ');
    // const[year, month, day] = dParts.split('-');
    const [hour, minute] = tParts.split(':');

    let amPM = 'AM';
    if (
      Number.parseInt(hour) >= 12 &&
      Number.parseInt(hour) <= 23 &&
      Number.parseInt(minute) <= 59
    )
      amPM = 'PM';

    if (endOfDay) {
      amPM = 'PM';
      input = `${dParts} 23:59 ${amPM}`;
    } else if (startOfDay) {
      amPM = 'AM';
      input = `${dParts} 00:00 ${amPM}`;
    } else {
      input = `${dParts} ${hour}:${minute} ${amPM}`;
    }
  }
  if (input && dateFormat2.test(input)) {
    const [year, month, day] = input.split('-');
    input = `${month}/${day}/${year}`;
  }

  input = input.replace(/\D/g, '');

  input = input.substring(0, 12);
  const size = input.length;

  if (size < 3) {
    return input;
  }
  if (size < 5) {
    return `${input.substring(0, 2)}/${input.substring(2, 4)}`;
  }
  if (size < 9) {
    return `${input.substring(0, 2)}/${input.substring(2, 4)}/${input.substring(
      4
    )}`;
  }
  if (size < 13) {
    let output = `${input.substring(0, 2)}/${input.substring(
      2,
      4
    )}/${input.substring(4, 8)} ${input.substring(8, 10)}:${input.substring(
      10
    )}`;
    return output;
  }
};

export const formatDateTimeInputs = (value: string) => {
  const dateFormat2 = RegExp(
    /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/
  );
  let input = value || '';

  // if seeding an initial value that is not "MM/dd/yyyy"
  if (input && input.includes('T')) {
    const [dParts, tParts] = input.split('T');
    const [year, month, day] = dParts.split('-');
    const [hour, minute, seconds] = tParts.split(':');
    let amPM = 'AM';
    if (
      Number.parseInt(hour) >= 12 &&
      Number.parseInt(hour) <= 23 &&
      Number.parseInt(minute) <= 59
    )
      amPM = 'PM';
    input = `${month}/${day}/${year} ${hour}:${minute} ` + amPM;
  } else if (input && input.includes(' ') && input.includes('/')) {
    const [dParts, tParts, miscParts] = input.split(' ');
    const [year, month, day] = dParts.split('/');
    const [hour, minute, seconds] = tParts.split(':');
    let amPM = 'AM';
    if (
      Number.parseInt(hour) >= 12 &&
      Number.parseInt(hour) <= 23 &&
      Number.parseInt(minute) <= 59
    )
      amPM = 'PM';
    input = `${month}/${day}/${year} ${hour}:${minute} ` + amPM;
  }
  if (input && input.includes('/') && dateFormat2.test(input)) {
    const [dParts, tParts, miscParts] = input.split(' ');
    const [year, month, day] = dParts.split('/');
    const [hour, minute, seconds] = tParts.split(':');
    let amPM = 'AM';
    if (
      Number.parseInt(hour) >= 12 &&
      Number.parseInt(hour) <= 23 &&
      Number.parseInt(minute) <= 59
    )
      amPM = 'PM';
    input = `${month}/${day}/${year} ${hour}:${minute} ` + amPM;
  }
  if (input && input.includes('-') && dateFormat2.test(input)) {
    const [dParts, tParts, miscParts] = input.split(' ');
    const [year, month, day] = dParts.split('-');
    const [hour, minute, seconds] = tParts.split(':');
    let amPM = 'AM';
    if (
      Number.parseInt(hour) >= 12 &&
      Number.parseInt(hour) <= 23 &&
      Number.parseInt(minute) <= 59
    )
      amPM = 'PM';

    input = `${month}/${day}/${year} ${hour}:${minute} ` + amPM;
  }

  input = input.replace(/\D/g, '');

  input = input.substring(0, 12);
  const size = input.length;

  if (size < 3) {
    return input;
  }
  if (size < 5) {
    return `${input.substring(0, 2)}/${input.substring(2, 4)}`;
  }
  if (size < 9) {
    return `${input.substring(0, 2)}/${input.substring(2, 4)}/${input.substring(
      4
    )}`;
  }
  if (size < 13) {
    let output = `${input.substring(0, 2)}/${input.substring(
      2,
      4
    )}/${input.substring(4, 8)} ${input.substring(8, 10)}:${input.substring(
      10
    )}`;
    return output;
    // return input;
  }
};

export const createDateObject = (dateValOrString: string, isUtc?: boolean) => {
  // 2017-09-07T13:30:16.01
  let output = null;
  let dateString;
  const dateFormat = RegExp(/\d{1,2}\/\d{1,2}\/\d{4}/);
  const dateFormat2 = RegExp(/\d{4}-\d{2}-\d{2}/);
  let parsed = false;

  let year = 0;
  let month = 0;
  let day = 0;

  let hours = 0;
  let minutes = 0;
  let zone = 'AM';
  if (isDate(dateValOrString)) {
    /// 2000-01-01T05:00:00.000Z
    dateString = format(
      dateValOrString as unknown as Date | number,
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  } else {
    if (
      dateValOrString &&
      dateValOrString.length > 0 &&
      dateValOrString.charAt(10) === ' '
    ) {
      dateString = dateValOrString.replace(
        dateValOrString.substring(10, 11),
        'T'
      );
    } else {
      dateString = dateValOrString;
    }
  }

  if (!dateString) {
    return output;
  }

  if (dateString.includes('T') && dateString.includes('-')) {
    const [datePart, rest] = dateString.split('T');
    const dParts = datePart.split('-');
    year = +dParts[0];
    month = +dParts[1] - 1;
    day = +dParts[2];

    const [rest2, zonePart] = rest.split(' ');
    const tParts = rest2.split(':');
    hours = +tParts[0];
    minutes = +tParts[1];
    zone = zonePart;

    parsed = true;
  } else if (dateString.includes('T') && dateString.includes('/')) {
    const [datePart, rest] = dateString.split('T');
    const dParts = datePart.split('/');
    month = +dParts[0] - 1;
    day = +dParts[1];
    year = +dParts[2];

    const [rest2, zonePart] = rest.split(' ');
    const tParts = rest2.split(':');
    hours = +tParts[0];
    minutes = +tParts[1];
    zone = zonePart;

    parsed = true;
  } else if (dateFormat.test(dateString)) {
    const dParts = dateString.split('/');
    month = +dParts[0] - 1;
    day = +dParts[1];
    year = +dParts[2];
    parsed = true;
  } else if (dateFormat2.test(dateString)) {
    const dParts = dateString.split('-');
    year = +dParts[0];
    month = +dParts[1] - 1;
    day = +dParts[2];
    parsed = true;
  }
  if (parsed) {
    if (isUtc) {
      output = new Date(Date.UTC(year, month, day, hours, minutes));
    } else {
      output = new Date(year, month, day, hours, minutes);
    }
  }

  return output;
};

export const calculateAge = (dob: string): number => {
  let interval: Duration;
  try {
    interval = intervalToDuration({
      start: new Date(dob),
      end: new Date(),
    });
  } catch (error) {
    return 0;
  }

  return interval.years ? interval.years : 0;
};
export const calculateAgeAtInjury = (
  dob: string,
  injuryDate: string
): number => {
  let interval: Duration;
  try {
    interval = intervalToDuration({
      start: new Date(dob),
      end: new Date(injuryDate),
    });
  } catch (error) {
    return 0;
  }

  return interval.years ? interval.years : 0;
};

export const displayDateOnly = (dateValOrString: string | Date) => {
  let output;
  let dateString: string;
  const dateFormat = RegExp(/\d{2}\/\d{2}\/\d{4}/);
  const dateFormat2 = RegExp(/\d{4}-\d{2}-\d{2}/);
  if (isDate(dateValOrString) && dateValOrString instanceof Date) {
    /// 2000-01-01T05:00:00.000Z
    dateString = format(
      dateValOrString as unknown as Date | number,
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  } else {
    dateString = dateValOrString as string;
  }

  if (!dateString) {
    output = '';
    return output;
  }

  if (dateString.includes('T')) {
    const [datePart, ...rest] = dateString.split('T');
    const [year, month, day] = datePart.split('-');
    output = `${month}/${day}/${year}`;
    return output;
  }

  if (dateFormat.test(dateString)) {
    output = dateString;
    return output;
  } else if (dateFormat2.test(dateString)) {
    const [year, month, day] = dateString.split('-');
    output = `${month}/${day}/${year}`;
    return output;
  }
  return output;
};
export const displayDateAndTime = (dateValOrString: string | Date) => {
  let output;
  let dateString: string;
  const dateFormat = RegExp(/\d{2}\/\d{2}\/\d{4}/);
  const dateFormat2 = RegExp(/\d{4}-\d{2}-\d{2}/);
  if (isDate(dateValOrString) && dateValOrString instanceof Date) {
    /// 2000-01-01T05:00:00.000Z
    dateString = format(
      dateValOrString as unknown as Date | number,
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  } else {
    dateString = dateValOrString as string;
  }

  if (!dateString) {
    output = '';
    return output;
  }

  if (dateString.includes('T')) {
    // added the "Z" to make it UTC time
    const zoned = utcToZonedTime(dateString + 'Z', 'America/New_York');
    output = format(zoned, 'MM/dd/yyyy hh:mm a');
    return output;
  }

  if (dateFormat.test(dateString)) {
    output = dateString;
    return output;
  } else if (dateFormat2.test(dateString)) {
    const [year, month, day] = dateString.split('-');
    output = `${month}/${day}/${year}`;
    return output;
  }
  return output;
};

export const displayDateAndTimeLocal = (dateValOrString: string | Date) => {
  let output;
  let dateString: string;
  const dateFormat = RegExp(/\d{2}\/\d{2}\/\d{4}/);
  const dateFormat2 = RegExp(/\d{4}-\d{2}-\d{2}/);
  if (isDate(dateValOrString) && dateValOrString instanceof Date) {
    /// 2000-01-01T05:00:00.000Z
    dateString = format(
      dateValOrString as unknown as Date | number,
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  } else {
    dateString = dateValOrString as string;
  }

  if (!dateString) {
    output = '';
    return output;
  }

  if (dateString.includes('T')) {
    // added the "Z" to make it UTC time
    const zoned = utcToZonedTime(dateString + 'Z', 'UTC');
    output = format(zoned, 'MM/dd/yyyy hh:mm a');
    return output;
  }

  if (dateFormat.test(dateString)) {
    output = dateString;
    return output;
  } else if (dateFormat2.test(dateString)) {
    const [year, month, day] = dateString.split('-');
    output = `${month}/${day}/${year}`;
    return output;
  }
  return output;
};
export const parseStringToBoolean = (value: string, name?: string) => {
  return value === 'true';
};

export const parseDatesForServer = (value: string, name?: string) => {
  if (value) {
    let d: string;
    try {
      d = format(createDateObject(value)!, 'yyyy-MM-dd');
      return d;
    } catch (error) {
      return value;
    }
  }
  return null;
};

export const parseTimeForServer = (value: string, name?: string) => {
  let d: string;
  try {
    d = format(createDateObject(value)!, 'hh:mm aa');
    return d;
  } catch (error) {
    return value;
  }
};

export const parseDateTimesForServer = (value: string) => {
  let d: string;
  try {
    d = format(createDateObject(value)!, "yyyy-MM-dd'T'HH:mm:ss");
    return d;
  } catch (error) {
    return value;
  }
};

export const setAddressTextString = (
  address: Address | null,
  setter: (value: React.SetStateAction<string>) => void
) => {
  if (address) {
    if (address.address2) {
      const text = `${address.address1}
${address.address2}
${address.city}, ${address.state} ${address.zip}`;
      setter(text);
    } else {
      const text = `${address.address1}
${address.city}, ${address.state} ${address.zip}`;
      setter(text);
    }
  } else {
    setter('');
  }
};

export const base64ToBlob = (
  b64Data: string,
  contentType = '',
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const downloadExcel = (fileName: string, file: string) => {
  const blob = base64ToBlob(
    file,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  );

  saveAs(blob, fileName);
};
export const downloadTxt = (fileName: string, file: string) => {
  const blob = base64ToBlob(file, 'application/txt');

  saveAs(blob, fileName);
};
export const downloadCsv = (fileName: string, file: string) => {
  const blob = base64ToBlob(file, 'text/csv');

  saveAs(blob, fileName);
};
export const downloadWav = (fileName: string, file: string) => {
  const blob = base64ToBlob(file, 'audio/wav');

  saveAs(blob, fileName);
};
export const downloadRpt = (fileName: string, file: string) => {
  const blob = base64ToBlob(file, 'application/x-rpt');

  saveAs(blob, fileName);
};
export const downloadIif = (fileName: string, file: string) => {
  const blob = base64ToBlob(file, 'text/iif');

  saveAs(blob, fileName);
};

export const downloadCrystal = (fileName: string, file: string) => {
  const blob = base64ToBlob(file, 'application/x-javascript');

  saveAs(blob, fileName);
};
export const downloadPdf = (fileName: string, file: string) => {
  const blob = base64ToBlob(file, 'application/pdf');

  saveAs(blob, fileName);
};
export const downloadPgp = (fileName: string, file: string) => {
  const blob = base64ToBlob(file, 'application/pgp');

  saveAs(blob, fileName);
};
export const downloadMsg = (fileName: string, file: string) => {
  const blob = base64ToBlob(file, 'application/vnd.ms-outlook');

  saveAs(blob, fileName);
};
export const downloadMp3 = (fileName: string, file: string) => {
  const blob = base64ToBlob(file, 'audio/mpeg');

  saveAs(blob, fileName);
};

export const downloadZip = (fileName: string, file: string) => {
  const blob = base64ToBlob(file, 'application/zip');

  saveAs(blob, fileName);
};

export const downloadAny = (fileName: string, file: string) => {
  const mimeType = getMimeType(fileName);
  console.log(mimeType);
  if (!mimeType) return;

  const blob = base64ToBlob(file, mimeType);

  saveAs(blob, fileName);
};

export function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number
): (...args: Params) => void {
  let timer: NodeJS.Timeout;
  return (...args: Params) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export const getMimeType = (fileName: string) => {
  let mimeType = '';
  if (!fileName) return mimeType;
  const extensions = Object.keys(mimeTypeMap) as (keyof typeof mimeTypeMap)[];
  extensions.forEach((ext) => {
    if (fileName.endsWith(ext)) {
      mimeType = mimeTypeMap[ext];
      return;
    }
  });
  return mimeType;
};

export const mimeTypeMap = {
  mpega: 'audio/x-mpeg',
  ps: 'application/postscript',
  aiff: 'audio/x-aiff',
  aim: 'application/x-aim',
  art: 'image/x-jg',
  asx: 'video/x-ms-asf',
  ulw: 'audio/basic',
  avi: 'video/x-msvideo',
  avx: 'video/x-rad-screenplay',
  bcpio: 'application/x-bcpio',
  exe: 'application/octet-stream',
  dib: 'image/bmp',
  html: 'text/html',
  cdf: 'application/x-cdf',
  cer: 'application/pkix-cert',
  class: 'application/java',
  cpio: 'application/x-cpio',
  csh: 'application/x-csh',
  css: 'text/css',
  csv: 'text/csv',
  doc: 'application/msword',
  dtd: 'application/xml-dtd',
  dv: 'video/x-dv',
  dvi: 'application/x-dvi',
  eot: 'application/vnd.ms-fontobject',
  etx: 'text/x-setext',
  gif: 'image/gif',
  gtar: 'application/x-gtar',
  gz: 'application/x-gzip',
  hdf: 'application/x-hdf',
  hqx: 'application/mac-binhex40',
  htc: 'text/x-component',
  ief: 'image/ief',
  jad: 'text/vnd.sun.j2me.app-descriptor',
  jar: 'application/java-archive',
  java: 'text/x-java-source',
  jnlp: 'application/x-java-jnlp-file',
  jpg: 'image/jpeg',
  js: 'application/javascript',
  txt: 'text/plain',
  json: 'application/json',
  midi: 'audio/midi',
  latex: 'application/x-latex',
  m3u: 'audio/x-mpegurl',
  pnt: 'image/x-macpaint',
  tr: 'text/troff',
  mathml: 'application/mathml+xml',
  mif: 'application/x-mif',
  qt: 'video/quicktime',
  movie: 'video/x-sgi-movie',
  mpa: 'audio/mpeg',
  mp4: 'video/mp4',
  mp3: 'audio/mpeg',
  mpg: 'video/mpeg',
  mpv2: 'video/mpeg2',
  msg: 'application/vnd.ms-outlook',
  src: 'application/x-wais-source',
  nc: 'application/x-netcdf',
  oda: 'application/oda',
  odb: 'application/vnd.oasis.opendocument.database',
  odc: 'application/vnd.oasis.opendocument.chart',
  odf: 'application/vnd.oasis.opendocument.formula',
  odg: 'application/vnd.oasis.opendocument.graphics',
  odi: 'application/vnd.oasis.opendocument.image',
  odm: 'application/vnd.oasis.opendocument.text-master',
  odp: 'application/vnd.oasis.opendocument.presentation',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odt: 'application/vnd.oasis.opendocument.text',
  otg: 'application/vnd.oasis.opendocument.graphics-template',
  oth: 'application/vnd.oasis.opendocument.text-web',
  otp: 'application/vnd.oasis.opendocument.presentation-template',
  ots: 'application/vnd.oasis.opendocument.spreadsheet-template',
  ott: 'application/vnd.oasis.opendocument.text-template',
  ogx: 'application/ogg',
  ogv: 'video/ogg',
  spx: 'audio/ogg',
  otf: 'application/x-font-opentype',
  flac: 'audio/flac',
  anx: 'application/annodex',
  axa: 'audio/annodex',
  axv: 'video/annodex',
  xspf: 'application/xspf+xml',
  pbm: 'image/x-portable-bitmap',
  pict: 'image/pict',
  pdf: 'application/pdf',
  pgm: 'image/x-portable-graymap',
  pls: 'audio/x-scpls',
  png: 'image/png',
  pnm: 'image/x-portable-anymap',
  ppm: 'image/x-portable-pixmap',
  pps: 'application/vnd.ms-powerpoint',
  psd: 'image/vnd.adobe.photoshop',
  pgp: 'application/pgp',
  qtif: 'image/x-quicktime',
  ras: 'image/x-cmu-raster',
  rdf: 'application/rdf+xml',
  rgb: 'image/x-rgb',
  rm: 'application/vnd.rn-realmedia',
  rtf: 'application/rtf',
  rtx: 'text/richtext',
  rpt: 'application/x-javascript',
  sfnt: 'application/font-sfnt',
  sh: 'application/x-sh',
  shar: 'application/x-shar',
  sit: 'application/x-stuffit',
  sv4cpio: 'application/x-sv4cpio',
  sv4crc: 'application/x-sv4crc',
  svgz: 'image/svg+xml',
  swf: 'application/x-shockwave-flash',
  tar: 'application/x-tar',
  tcl: 'application/x-tcl',
  tex: 'application/x-tex',
  texinfo: 'application/x-texinfo',
  tiff: 'image/tiff',
  tsv: 'text/tab-separated-values',
  ttf: 'application/x-font-ttf',
  ustar: 'application/x-ustar',
  vxml: 'application/voicexml+xml',
  xbm: 'image/x-xbitmap',
  xhtml: 'application/xhtml+xml',
  xls: 'application/vnd.ms-excel',
  xsl: 'application/xml',
  xpm: 'image/x-xpixmap',
  xslt: 'application/xslt+xml',
  xul: 'application/vnd.mozilla.xul+xml',
  xwd: 'image/x-xwindowdump',
  vsd: 'application/vnd.visio',
  wav: 'audio/x-wav',
  wbmp: 'image/vnd.wap.wbmp',
  wml: 'text/vnd.wap.wml',
  wmlc: 'application/vnd.wap.wmlc',
  wmls: 'text/vnd.wap.wmlsc',
  wmlscriptc: 'application/vnd.wap.wmlscriptc',
  wmv: 'video/x-ms-wmv',
  woff: 'application/font-woff',
  woff2: 'application/font-woff2',
  wrl: 'model/vrml',
  wspolicy: 'application/wspolicy+xml',
  z: 'application/x-compress',
  zip: 'application/zip',
  iif: 'text/iif',
};
// SORT ALPHABETICAL EXAMPLE
// export const formularySort = (employers: Employer[]) => {
//   return employers.sort((a, b) => {
//     const aName = (a.name ?? "").toUpperCase();
//     const bName = (b.name ?? "").toUpperCase();
//     return aName < bName ? -1 : aName > bName ? 1 : 0;
//   });
// };

export const stripHtml = (input: string | null) => {
  if (input === null) return '';
  const regex = /<\/?[^>]+(>|$)/g;
  ///<\/?[^>]+(>|$)/g
  const output = input
    .replace(new RegExp(regex), '')
    .replace(new RegExp(regex), '');
  return output;
};
