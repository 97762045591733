import React from 'react';
import { Field } from 'react-final-form';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import { centsValidation, requiredField } from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import { AddEditUserFormType } from './AddEditUserFormType';
import { FormApi } from 'final-form';

export default function UserForm({
  values,
  isNew,
  form,
}: {
  values: AddEditUserFormType;
  isNew: boolean;
  form: FormApi<AddEditUserFormType, Partial<AddEditUserFormType>>;
}) {
  return (
    <div>
      <div className={`${styles.flex3}`}>
        <Field
          name='userName'
          label='Full Name'
          type='text'
          validate={requiredField}
          component={FieldBSRenderText}
        />
        <Field
          name='adAccount'
          label='User Id'
          type='text'
          validate={requiredField}
          component={FieldBSRenderText}
        />
        <Field
          name='initials'
          label='Initials'
          type='text'
          component={FieldBSRenderText}
        />
      </div>
      <div className={`${styles.flex3}`}>
        <Field
          name='phoneNumber'
          label='Cell Phone'
          type='text'
          component={FieldBSRenderText}
        />
        <Field
          name='workPhone'
          label='Office Phone'
          type='text'
          component={FieldBSRenderText}
        />
        <Field
          name='fax'
          label='Fax'
          type='text'
          component={FieldBSRenderText}
        />
      </div>
      <div className={`${styles.flex2}`}>
        <Field
          name='emailAddress'
          label='Email Address'
          type='text'
          component={FieldBSRenderText}
        />
        <Field
          name='password'
          label='Password'
          type='text'
          validate={isNew && values.adAccount ? requiredField : undefined}
          component={FieldBSRenderText}
        />
      </div>
      <div className={`${styles.grid7}`}>
        <Field
          name='active'
          type='checkbox'
          label='Active'
          checked={values.active === true}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='isClaimsAssistant'
          type='checkbox'
          label='Claims Assistant'
          checked={values.isClaimsAssistant === true}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='isNcm'
          type='checkbox'
          label='NCM'
          checked={values.isNcm === true}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='isAdmin'
          type='checkbox'
          label='Admin'
          checked={values.isAdmin === true}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='isAdjuster'
          type='checkbox'
          label='Adjuster'
          checked={values.isAdjuster === true}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='isSupervisor'
          type='checkbox'
          label='Supervisor'
          checked={values.isSupervisor === true}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='isManageAch'
          type='checkbox'
          label='Manage ACH'
          checked={values.isManageAch === true}
          component={FieldBSRenderCheckbox}
        />
        {values.isAdjuster && (
          <Field
            name='maximumReserveApproval'
            label='max Approval Amount'
            type='text'
            format={formatNumbers}
            parse={cleanMoney}
            validate={centsValidation}
            component={FieldBSRenderMoney}
          />
        )}
      </div>
    </div>
  );
}
