import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { TextLog } from '../ApiTypes/TextLog';
import { TextGridObj } from '../ApiTypes/TextGridObj';

class TextLogApi {
  static getConversationTextList(claimantphonenumber: string) {
    return axios.get<TextLog[]>(
      `${API_URL}/api/textlogs/${claimantphonenumber}`,
      getAuthHeader()
    );
  }
  static addOutgoingLog(request: TextLog) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/textlogs`,
      request,
      getAuthHeader()
    );
  }
  static getLogs() {
    return axios.get<TextGridObj[]>(
      `${API_URL}/api/textlogs/list`,
      getAuthHeader()
    );
  }
}

export default TextLogApi;
