import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { ThirdPartyApiservice } from '../ApiTypes/ThirdPartyApiservice';
import { NaviRequest } from '../ApiTypes/NaviRequest';

class ThirdPartyApi {
  static getThirdPartyServices() {
    return axios.get<ThirdPartyApiservice[]>(
      `${API_URL}/api/thirdparty/services`,
      getAuthHeader()
    );
  }
  static sendThirdPartyInfo(request: NaviRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/thirdparty`,
      request,
      getAuthHeader()
    );
  }
}

export default ThirdPartyApi;
