import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ClaimApi from '../../Api/ClaimApi';
import DocumentsApi from '../../Api/DocumentsApi';
import PbmApi from '../../Api/PbmApi';
import UserEmailListApi from '../../Api/UserEmailListApi';
import { ChangeClaimNumberRequest } from '../../ApiTypes/ChangeClaimNumberRequest';
import { EmailDocumentsRequest } from '../../ApiTypes/EmailDocumentsRequest';
import { ExportDocumentsRequest } from '../../ApiTypes/ExportDocumentsRequest';
import { Pbm } from '../../ApiTypes/Pbm';
import { UserEmailList } from '../../ApiTypes/UserEmailList';
import { useAppSelector } from '../../Reducers/Store';
import { downloadPdf } from '../../Utils';
import ChangeClaimNumberModal from '../ChangeClaimNumber/ChangeClaimNumberModal';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import ClaimDrugScreenModal from '../DrugScreen/ClaimDrugScreenModal';
import EmailDocumentsModal from '../EmailDocuments/EmailDocumentsModal';
import ExportDocsModal from '../ExportDocs/ExportDocsModal';
import LinkClaimModal from '../LinkClaim/LinkClaimModal';
import RxBridgeRequestModal from '../RxBridgeRequest/RxBridgeRequestModal';
import { DocumentType } from '../../ApiTypes/DocumentType';
import { ScanDocSimple } from '../../ApiTypes/ScanDocSimple';
import TriStarrInvestigationsModal from '../ThirdPartyIntegration/TriStarrInvestigationsModal';

export default function ClaimActionButton() {
  const { claimNumber } = useParams();
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<JSX.Element>(<div />);
  const [confirmAction, setConfirmAction] = useState<string>('');

  const [showTriStarr, setShowTriStarr] = useState<boolean>(false);
  const [showLinkClaim, setShowLinkClaim] = useState<boolean>(false);
  const [showExportDocs, setShowExportDocs] = useState<boolean>(false);
  const [showChangeClaimNumber, setShowChangeClaimNumber] =
    useState<boolean>(false);
  const [showDrugScreen, setShowDrugScreen] = useState<boolean>(false);
  const [showRxBridgeRequest, setShowRxBridgeRequest] =
    useState<boolean>(false);
  const [showEmailDocumentsModal, setShowEmailDocumentsModal] =
    useState<boolean>(false);
  const [emailDocumentsRequest, setEmailDocumentsRequest] =
    useState<EmailDocumentsRequest | null>(null);
  const [userEmailList, setUserEmailList] = useState<UserEmailList[]>([]);
  const [scanDocs, setScanDocs] = useState<ScanDocSimple[]>([]);

  const { pbms } = useAppSelector((state) => state.reference);
  const { userModel } = useAppSelector((state) => state.user);
  const { claim, claimant, employer } = useAppSelector(
    (state) => state.currentClaimReducer
  );
  const DELETE = 'delete';

  useEffect(() => {
    getUserEmailList();
  }, [userModel]);
  useEffect(() => {
    getScanDocsForClaim();
  }, [claimNumber]);

  const getUserEmailList = () => {
    if (userModel?.user?.userId) {
      UserEmailListApi.getEmailList(userModel?.user?.userId)
        .then((res) => {
          setUserEmailList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setUserEmailList([]);
    }
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const handleDeleteClick = () => {
    if (claimNumber) {
      setConfirmMessage(
        <h5 className='text-danger'>
          Are you sure you want to delete this claim {claimNumber}?
        </h5>
      );
      setConfirmAction(DELETE);
      setShowConfirm(true);
    }
  };

  const handleLinkClaimClick = () => {
    if (claimNumber) {
      setShowLinkClaim(true);
    }
  };

  const handleConfirming = () => {
    switch (confirmAction) {
      case DELETE:
        deleteClaim();
        break;

      default:
        break;
    }
  };

  const handleLinkResult = (claimNumber: string) => {
    console.log(claimNumber);
    console.log(claimNumber);
    setShowLinkClaim(false);
    navigate(`/claims/${claimNumber}`);
  };

  const deleteClaim = () => {
    if (claimNumber) {
      ClaimApi.deleteClaim(claimNumber)
        .then((res) => {
          if (res.data.success) {
            toast.success(`Claim ${claimNumber} deleted`);
            navigate('/');
          } else {
            // sometimes there is an error from stored procedure, yet claim is deleted
            ClaimApi.getClaimByClaimNumber(claimNumber)
              .then((r) => {
                if (r.data.claimNo) {
                  // not deleted
                  toast.error(res.data.message);
                } else {
                  toast.success(`Claim ${claimNumber} deleted`);
                  navigate('/');
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete claim');
        });
    }
  };

  const handleExportDocsClick = () => {
    setShowExportDocs(true);
  };
  const exportDocs = (values: { claimNumber: string; insurcoid: number }) => {
    const request: ExportDocumentsRequest = {
      claimNumber: values.claimNumber ?? '',
      insuranceCompanyId: values.insurcoid ?? 0,
    };

    DocumentsApi.exportDocuments(request);
    setShowExportDocs(false);
  };

  const handleChangeClaimNumberClick = () => {
    if (claimNumber) {
      setShowChangeClaimNumber(true);
    }
  };

  const tryClaimNumberChange = (values: ChangeClaimNumberRequest) => {
    return ClaimApi.changeClaimNumber(values)
      .then((res) => {
        if (res.data.success) {
          setShowChangeClaimNumber(false);
          navigate(`/claims/${res.data.affectedEntityIdentifier}`);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to change claim numbers');
      });
  };

  const handleDrugScreenClick = () => {
    setShowDrugScreen(true);
  };
  const sendNewClaimEmail = () => {
    if (claimNumber) {
      ClaimApi.sendnewclaimemail(claimNumber)
        .then((res) => {
          toast.success(`Claim ${claimNumber} new claim email sent`);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to assign PBM');
        });
    }
  };

  const assignPbm = (p: Pbm) => {
    if (claimNumber) {
      // handle RxBridge
      if (p.pbmid === 6) {
        setShowRxBridgeRequest(true);
      } else {
        const request = {
          startDate: null,
          endDate: null,
          pbmId: p.pbmid,
          claimNumber,
        };
        PbmApi.assignPbm(request)
          .then((res) => {
            if (res.data.subject !== 'Failed') {
              handleEmailDocumentRequest(res.data);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error('Failed to assign PBM');
          });
      }
    }
  };

  const handleTriStarrClick = () => {
    setShowTriStarr(true);
  };

  const getScanDocsForClaim = () => {
    if (claimNumber) {
      DocumentsApi.getScanDocsForClaim(claimNumber, DocumentType.All)
        .then((res) => {
          setScanDocs(res.data);
        })
        .catch((err) => {
          console.log(err);
          setScanDocs([]);
        });
    }
  };

  const handleEmailDocumentRequest = async (send: EmailDocumentsRequest) => {
    setEmailDocumentsRequest(send);
    setShowEmailDocumentsModal(true);
  };

  const handleEmailDocuments = (values: EmailDocumentsRequest) => {
    return DocumentsApi.emailDocs(values)
      .then((res) => {
        if (res.data.success) {
          setShowEmailDocumentsModal(false);
          setEmailDocumentsRequest(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to share documents');
      });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant='light'
        id='claimActions'
        className='button-icon-text'
      >
        Claim Actions
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          as='button'
          onClick={() => {
            handleDeleteClick();
          }}
        >
          Delete Claim
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={() => {
            handleLinkClaimClick();
          }}
        >
          Link This Claim
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={() => {
            handleExportDocsClick();
          }}
        >
          Export Documents
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={() => {
            handleChangeClaimNumberClick();
          }}
        >
          Change Claim Number
        </Dropdown.Item>

        <Dropdown drop='end'>
          <Dropdown.Toggle
            variant='light'
            id='claimReports'
            className='button-icon-text'
          >
            Assign PBM
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {pbms.map((p) => {
              return (
                <Dropdown.Item
                  key={p.pbmid}
                  as='button'
                  onClick={() => {
                    assignPbm(p);
                  }}
                >
                  {p.name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown.Item
          as='button'
          onClick={() => {
            handleDrugScreenClick();
          }}
        >
          Drug Screen Info
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={() => {
            sendNewClaimEmail();
          }}
        >
          Send Test New Claim Email
        </Dropdown.Item>
        <Dropdown drop='end'>
          <Dropdown.Toggle
            variant='light'
            id='thirdPartyActions'
            className='button-icon-text'
          >
            3rd Party Integrations
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as='button' onClick={handleTriStarrClick}>
              Tri-Starr Investigations
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Dropdown.Menu>
      <ConfirmModal
        show={showConfirm}
        setShow={closeConfirm}
        message={confirmMessage}
        confirm={handleConfirming}
      />
      <ExportDocsModal
        show={showExportDocs}
        setShow={setShowExportDocs}
        claimNumber={claimNumber ?? ''}
        confirm={exportDocs}
      />
      <ChangeClaimNumberModal
        show={showChangeClaimNumber}
        setShow={setShowChangeClaimNumber}
        claimNumber={claimNumber ?? ''}
        confirm={tryClaimNumberChange}
      />
      <ClaimDrugScreenModal
        show={showDrugScreen}
        setShow={setShowDrugScreen}
        claimNumber={claimNumber ?? ''}
      />
      <RxBridgeRequestModal
        show={showRxBridgeRequest}
        setShow={setShowRxBridgeRequest}
        claimNumber={claimNumber!}
      />
      <EmailDocumentsModal
        show={showEmailDocumentsModal}
        setShow={setShowEmailDocumentsModal}
        claimNumber={claimNumber!}
        docs={scanDocs.filter(
          (x) =>
            x.nameAndDatesText.toLowerCase().includes('wc1') ||
            x.nameAndDatesText.toLowerCase().includes('wc-1')
        )}
        handleEmailDocuments={handleEmailDocuments}
        userEmailList={userEmailList}
        subject={emailDocumentsRequest?.subject}
        body={emailDocumentsRequest?.message}
        toEmailAddresses={emailDocumentsRequest?.emailAddresses}
      />
      {showLinkClaim && (
        <LinkClaimModal
          show={showLinkClaim}
          setShow={setShowLinkClaim}
          confirm={handleLinkResult}
        />
      )}
      <TriStarrInvestigationsModal
        show={showTriStarr}
        setShow={setShowTriStarr}
        claim={claim}
        claimant={claimant}
        employer={employer}
        docs={scanDocs}
        //   docs={scanDocs.filter(
        //   (x) =>
        //     x.nameAndDatesText.toLowerCase().includes('wc1') ||
        //     x.nameAndDatesText.toLowerCase().includes('wc-1')
        // )}
      />
    </Dropdown>
  );
}
