import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DocumentsApi from '../../Api/DocumentsApi';
import { toast } from 'react-toastify';
import { FileDownload } from '../../Types/FileDownLoad';
import { FaFileAlt } from 'react-icons/fa';

import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css';
import DownloadComponent from '../DownloadComponent/DownloadComponent';
import JpgComponent from '../JpgComponent/JpgComponent';
import PdfComponent from '../PdfComponent/PdfComponent';
import PngComponent from '../PngComponent/PngComponent';
import { TIFFViewer } from '../TiffComponent/TiffComponent';
import Loading from '../Common/Loading';

export default function DocViewPage() {
  let { id } = useParams();
  const location = useLocation();
  const [fileDownload, setFileDownload] = useState<FileDownload | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [noFile, setNoFile] = useState<boolean>(false);

  useEffect(() => {
    getFile();
  }, [id]);

  const getFile = () => {
    if (!id || id === 'undefined') {
      toast.error('No Document Selected');
      setLoading(false);
      return;
    }
    if (id) {
      const split = location.pathname.split('/');
      const path = split[split.length - 2];
      if (path === 'scandocs') {
        getScanDoc(+id);
      } else {
        getPendingScanDoc(+id);
      }
    }
  };

  const getScanDoc = (docId: number) => {
    return DocumentsApi.getScanDocFile(docId)
      .then((res) => {
        setLoading(false);
        if (res.data.file.length > 1) {
          setFileDownload(res.data);
          setNoFile(false);
        } else {
          toast.error('Unable to find document');
          setNoFile(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error('Failed to get document');
        setNoFile(true);
      });
  };
  const getPendingScanDoc = (docId: number) => {
    if (docId) {
      DocumentsApi.getScanDocPendingFile(docId)
        .then((res) => {
          setLoading(false);
          if (res.data.file.length > 1) {
            setFileDownload(res.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast.error('Failed to get document');
        });
    } else {
      setLoading(false);
    }
  };

  const getComponent = (fileDownload: FileDownload) => {
    console.log(fileDownload);
    if (
      fileDownload?.fileName &&
      (fileDownload?.fileName.toLowerCase().endsWith('.jpg') ||
        fileDownload?.fileName.toLowerCase().endsWith('.jpeg'))
    ) {
      return (
        <JpgComponent
          fileName={fileDownload?.fileName}
          base64Data={fileDownload?.file}
        />
      );
    } else if (
      fileDownload?.fileName &&
      fileDownload?.fileName.toLowerCase().endsWith('.png')
    ) {
      return (
        <PngComponent
          fileName={fileDownload?.fileName}
          base64Data={fileDownload?.file}
        />
      );
    } else if (
      fileDownload?.fileName &&
      fileDownload?.fileName.toLowerCase().endsWith('.pdf')
    ) {
      return (
        <PdfComponent
          base64Data={fileDownload?.file}
          fileName={fileDownload?.fileName}
        />
      );
    } else if (
      fileDownload?.fileName &&
      (fileDownload?.fileName.toLowerCase().endsWith('.tiff') ||
        fileDownload?.fileName.toLowerCase().endsWith('.tif'))
    ) {
      return (
        <TIFFViewer
          base64Data={fileDownload?.file}
          fileName={fileDownload?.fileName}
        />
      );
    } else {
      return (
        <DownloadComponent
          fileName={fileDownload?.fileName}
          base64Data={fileDownload?.file}
        />
      );
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='w-100 h-100'>
      <h1 className='d-flex gap1Rem fs-4 p-3'>
        <FaFileAlt className='pe-1' /> {fileDownload?.fileName ?? ''}
      </h1>
      <ScrollContainer mouseScroll={{ ignoreElements: 'input, button' }}>
        <div id='DocViewModal'>
          {fileDownload && getComponent(fileDownload)}
          {!fileDownload && noFile && <div>Unable to find document</div>}
        </div>
      </ScrollContainer>
    </div>
  );
}
