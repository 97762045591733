export interface DevExpressReportRequest {
  accountNumber: string;
  claimNumber: string;
  employerId: number | null;
  fromDate: string | null;
  asOfDate: string | null;
  insurCoId: number;
  startYear: number;
  endYear: number;
  external: boolean;
  openOnly: boolean;
  report: DxAvailableReports;
  severity: string;
  status: string;
  toDate: string | null;
  userId: string;
  minReserveAmount: number;
  caseManagementId: number;
  letterId: number;
  policyId: number;
  reserveWorksheetId: number;
  eDITransactionId: number;
  keyDocRequestId: number;
  searchText: string;
  onlyClearedChecks: boolean;
  topInjuriesReportType: number;
  topInjuriesReportFilter: string;
  workStatusId: number;
  maskSSN: boolean;
  wC4Id: number;
  incidentOnly: boolean;
  useEOMData: boolean;
  useClaimDataForWc1: boolean;
  showAdjusterInfo: boolean;
  treatyYear: number;
  exportType: ExportTypes;
  nCMReportType: number;
}
export enum DxAvailableReports {
  rptLossRun,
  rptJobComparison,
  rptCheckRegister,
  rptExcessClaimsOverRetention,
  rptGADALetter,
  rptGEWCTLetter,
  rptLocationComparison,
  rptReserveHistory,
  rpt007SupervisorsReport,
  rpt17Line,
  rptAGTPremiumAndLosses,
  rptAGTReserveDifference,
  rptBartowFormc,
  rptCaseManagement,
  rptCCBOCFormc,
  rptCheckRegisterForsyth,
  rptCheckRequest,
  rptClaimAuditReport,
  rptClaimDrugScreen,
  rptClaimReserveReport,
  rptClaimsCountByAdjuster,
  rptClaimStatistics,
  rptClaimStatusChange,
  rptClaimsTrendline,
  rptClaimSummaryAT,
  rptCompanyReportPage1,
  rptCompanyReportPage2,
  rptCompanyReportPage3,
  rptCompCompleteFees,
  rptCompCompleteInvoice,
  rptCompIQStats,
  rptCompnyReportPage4,
  rptDashboardStats,
  rptDoctorNotesRequest,
  rptEnvelope,
  rptEnvelopeLetter,
  rptEORImage,
  rptExccessRecoveryReq,
  rptGAAckLetterFederated,
  rptGASLetter,
  rptGeneralLetter,
  rptHCLetter,
  rptLossSummary,
  rptMileageClaim,
  rptNCMInvoice,
  rptNewClaim,
  rptNewClaimReport,
  rptOpenClaimsListing,
  rptOutstandingChecks,
  rptPaymentHistory,
  rptPharmacySetupTemplate,
  rptPolicyBalanceSheet,
  rptPrintEor,
  rptReserveWorksheet,
  rptSendIndemnityDetails,
  rptTopInjuries,
  rptTotalPremiumAndLoss,
  rptWC1,
  rptWC2,
  rptWC207,
  rptWC2a,
  rptWC3,
  rptWC6,
  rptWC104,
  rptWCLossRun,
  rptEDIProgressReport,
  ediComparison,
  NCMReportByManager,
  rptPremiumAndLoss,
  rptDeductibles,
  rptNCMReport,
}

export enum ExportTypes {
  PDF = 1,
  XLS = 2,
  WORD = 3,
}
